import React from 'react';
// import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import initI18n from './i18n';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang') || 'zh-hant';
const { preloadState } = window;
const initState = {
  ...preloadState,
};

const { store, history } = configureStore(initState);
initI18n(lang);
render(
  <BrowserRouter>
    <App history={history} store={store} />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
