import { push } from 'connected-react-router';
import { call, put, getContext, takeLatest, select } from 'redux-saga/effects';
import * as types from '../actionTypes';
import { PageUrls, RecordType } from '@/utils/constant';
import { getAdminV2, getHandle } from '@/utils/common';
import _get from 'lodash/get'
import { CountryValueMap, FormKeyMap,GenderValueMap } from '@/components/PrimaryAuthFormArea/constant';
import TW_City_District_Map from '@/assets/locales/tw_city_district_map';
const ADMIN_V2 = getAdminV2();
const PRIMARY_AUTH_URL = 'kyc/basic';
const PHONE_REGION_CODE_MAP = {
  [CountryValueMap.sg]: '65',
  [CountryValueMap.tw]: '886',
};
function getQueryVariable (variable: string)  {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) { return pair[1]; }
  }
  return '';
}
function changeURLArg (url: string, arg: string, arg_val: string) {
  var pattern = arg + '=([^&]*)';
  var replaceText = arg + '=' + arg_val;
  if (url.match(pattern)) {
      var tmp = '/(' + arg + '=)([^&]*)/gi';
      tmp = url.replace(eval(tmp), replaceText);
      return tmp;
  } else {
      if (url.match('[\?]')) {
          return url + '&' + replaceText;
      } else {
          return url + '?' + replaceText;
      }
  }
  return url + '\n' + arg + '\n' + arg_val;
}
// 获取指定名称的cookie
function getCookie(name){
  var strcookie = document.cookie;//获取cookie字符串
  var arrcookie = strcookie.split("; ");//分割
  //遍历匹配
  for ( var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] == name){
          return arr[1];
      }
  }
  return "";
}

function* setLoading(status: boolean) {
  yield put({ type: types.setSubmitLoading, payload: status });
}

function *setSubmitError(error: string | null) {
  yield put({ type: types.setSubmitError, payload: error })
}


function getPostDataForPrimary(formDatas, businessType) {
  const descriptorPrefix = /^SL\*/;
  const billingDescriptor = descriptorPrefix.test(formDatas[FormKeyMap.billingDescriptor])
    ? formDatas[FormKeyMap.billingDescriptor]
    : 'SL*' + formDatas[FormKeyMap.billingDescriptor];

  const transactionName = formDatas[FormKeyMap.billingDescriptor];
    
  const phoneCode = PHONE_REGION_CODE_MAP[formDatas[FormKeyMap.nationality]];
  if (phoneCode && !/\+/.test(formDatas[FormKeyMap.phone])) {
    formDatas[FormKeyMap.phone] = `+${phoneCode}${formDatas[FormKeyMap.phone]}`
  }

  if (formDatas[FormKeyMap.totalCapital] === '0') {
    formDatas[FormKeyMap.totalCapital] = '000000000000000';
  }

  if(!formDatas[FormKeyMap.gender]){
    formDatas[FormKeyMap.gender] = GenderValueMap.male
  }

  console.log("postPrimaryData",{
    ...formDatas,
    merchantType: businessType,
    nationality: formDatas.nationality,
    sl_payment_billing_descriptor_name: billingDescriptor,
    transactionName:transactionName,
    regNumber: formDatas[FormKeyMap.companyId] || formDatas.idNumber,
    handle:getCookie("a_dhch")
  })
  return {
    ...formDatas,
    merchantType: businessType,
    nationality: formDatas.nationality,
    sl_payment_billing_descriptor_name: billingDescriptor,
    transactionName:transactionName,
    regNumber: formDatas[FormKeyMap.companyId] || formDatas.idNumber,
    handle:getCookie("a_dhch")
  };
 
}

function* submitPrimaryAuth({ payload: { formDatas, businessType } }: ReturnType<typeof types.submitPrimaryAuth>) {
   const { router } = yield select((store) => store);
  yield setLoading(true);
  yield setSubmitError(null);
  console.log("submitPrimaryFormDatas",formDatas)
  console.log("submitPrimaryBusinessType",businessType)
  try {
    const api = yield getContext('api');
    localStorage.setItem(businessType, JSON.stringify(formDatas));
 
    yield call(api.post, PRIMARY_AUTH_URL, getPostDataForPrimary(formDatas, businessType));
    yield setLoading(false);
    if (window.top !== window.self) {
      //TODO 初级资料提交 如何判断地区？
      if((formDatas.regCountry || formDatas.nationality)!==CountryValueMap.tw &&  (formDatas.regCountry || formDatas.nationality)!==CountryValueMap.cn){
        window.parent.postMessage({ type: 'redirectToPayments' }, '*')
      }
      else {
        const handle = getHandle(router.location.pathname, PageUrls.senior,ADMIN_V2);
        if(ADMIN_V2){
          yield put(push('/' + handle + PageUrls.senior+"?lang="+getQueryVariable("lang")));
          // changeURLArg(window.location.href,"lang",getQueryVariable("lang"))
        }
        else{
          setTimeout(function () {
            window.location.href = '/' + handle + PageUrls.senior+"?lang="+getQueryVariable("lang")
            // changeURLArg(window.location.href,"lang",getQueryVariable("lang"))
          },3000)
        }
      }
    } else {
      // redirect to preview page if not in iframe as a fallback
      const handle = getHandle(router.location.pathname, PageUrls.primary, ADMIN_V2);
      yield put(push('/' + handle + PageUrls.record + '?type=' + RecordType.primary));
    }
  } catch (e) {
    const errorMsg = _get(e, 'response.data.msg')
    if (errorMsg) {
      yield setSubmitError(errorMsg);
    }
    yield setLoading(false);
  }
}

export default function* watchSubmitPrimaryAuthSaga() {
  yield takeLatest(types.submitPrimaryAuth, submitPrimaryAuth);
}
