import * as types from './actionTypes';
import { handleActions } from 'redux-actions';
import { PrimaryRecordState } from '../../../../types/store';

const initState: PrimaryRecordState = {
  primaryFormDatas: {},
  primaryTimeline: [],
};

export default handleActions(
  new Map([
    [
      types.setPrimaryFormDatas,
      (state: PrimaryRecordState, action: any) => ({ ...state, primaryFormDatas: { ...action.payload } }),
    ],
    [types.setPrimaryTimeLine, (state, action) => ({ ...state, primaryTimeline: [...action.payload] })],
  ]) as { [key: string]: any },
  initState,
);
