import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import createApi from '../utils/api';
import createRootReducer from '../reducers';
import rootSagas from '../sagas';

export default (initState = {}) => {
  const history = createBrowserHistory();
  const [, merchantId] = window.location.pathname.split('/');
  const api = createApi(merchantId);
  const sagaMiddleware = createSagaMiddleware({
    context: {
      api,
    },
  });

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      // eslint-disable-next-line no-underscore-dangle
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(
    createRootReducer(history),

    initState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSagas);

  if ((module as any).hot) {
    (module as any).hot.accept('../reducers', () => {
      // eslint-disable-next-line global-require, import/no-unresolved
      const nextRootReducer = require('../reducers').default();
      store.replaceReducer(nextRootReducer);
    });
  }

  return {
    store,
    history,
  };
};
