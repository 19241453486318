import axios from 'axios';

import { getAdminV2 } from '@/utils/common';

const ADMIN_V2 = getAdminV2();

function createApi(merchantId = '') {
  const client = axios.create({
    baseURL: ADMIN_V2 ?  `/admin/kyc/api/` : `/${merchantId}/api/`,
    // when develop, please use below handle when u want 2.0
    // baseURL: `/admin/v2/api/`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
  });

  // client.interceptors.response.use((response) => response.data);

  return client;
}

export default createApi;
