import React from 'react';
import { LoadingSpinner } from '@shopline/dashboard-ui';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingScreen = () => (
  <LoadingContainer>
    <LoadingSpinner />
  </LoadingContainer>
);

export default LoadingScreen;
