import { PrimaryAuthState } from '@/types/store';
import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

const initState: PrimaryAuthState = {
  submitLoading: false,
  submitError: null,
};

export default handleActions(
  new Map([
    [
      types.setSubmitLoading,
      (state: PrimaryAuthState, action: any) => {
        return { ...state, submitLoading: action.payload };
      },
    ],
    [
      types.setSubmitError,
      (state: PrimaryAuthState, action: any) => {
        return { ...state, submitError: action.payload }
      }
    ]
  ]) as { [key: string]: any },
  initState,
);
