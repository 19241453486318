import * as types from './actionTypes';
import { handleActions } from 'redux-actions';

const initState = {
  isMalaysiaForm: false,
  lang: '',
};

export default handleActions(
  new Map([
    [types.setIsMalaysiaForm, (state, { payload }) => ({ ...state, isMalaysiaForm: payload })],
    [types.setLang, (state, { payload }) => ({ ...state, lang: payload })],
  ]) as { [key: string]: any },
  initState,
);
