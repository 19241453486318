import { createAction } from 'redux-actions';
import { BusinessType } from '../../components/SeniorAuthFormArea/constant';

export const submitPrimaryAuth = createAction(
  'SUBMIT_PRIMARY_AUTH',
  (formDatas: any, businessType: BusinessType[keyof BusinessType]) => ({
    formDatas,
    businessType,
  }),
);

export const setSubmitLoading = createAction('primary/submitLoading');

export const setSubmitError = createAction('primary/submitError');
