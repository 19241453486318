import { CountryValueMap } from '../components/PrimaryAuthFormArea/constant';

export interface PageUrls {
  primary: '/basic';
  senior: '/advance';
  record: '/record';
}

export const PageUrls: PageUrls = {
  primary: '/basic',
  senior: '/advance',
  record: '/record',
};

export const RecordType = {
  primary: 'basic',
  senior: 'advance',
};

export const CurrencyNationalityMapping = {
  'HKD': CountryValueMap.hk,
  'MYR': CountryValueMap.my,
  'USD': CountryValueMap.cn,
  'SGD': CountryValueMap.sg,
  'TWD': CountryValueMap.tw,
};

export enum Currencies {
  HKD = 'HKD',
  USD = 'USD',
  MYR = 'MYR',
  SGD = 'SGD',
  TWD = 'TWD'
}
