import { createAction } from 'redux-actions';

export const getRegion = createAction('GET_REGION', (payload?) => payload);
export const setRegion = createAction('SET_REGION');

export const getLocations = createAction('GET_LOCATIONS', (payload, resolve, reject) => {
  return { payload, resolve, reject };
});
export const setLocations = createAction('SET_LOCATIONS');

export const getBank = createAction('GET_BANK', (payload) => payload);
export const setBank = createAction('SET_BANK');

export const getBankBranches = createAction('GET_BANK_BRANCHES', (payload, resolve, reject) => {
  return { payload, resolve, reject };
});
export const setBankBranches = createAction('SET_BANK_BRANCHES');

export const getShopInfo = createAction('GET_SHOP_INFO', (payload, resolve, reject) => ({ payload, resolve, reject }));
export const setShopInfo = createAction('SET_SHOP_INFO');

export const uploadFile = createAction('UPLOAD_FILE', (payload, resolve, reject) => ({ payload, resolve, reject }));

export const getBankOptions = createAction('GET_BANK_OPTIONS', (payload, resolve, reject) => {
  return { payload, resolve, reject };
});
