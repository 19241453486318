import * as types from '../actionTypes';
import { put, takeLatest, getContext, call } from 'redux-saga/effects';

function* getPrimaryRecord() {
  const api = yield getContext('api');

  const result = yield call(api.get, 'kyc/info');
  let data = result.data;

  yield put({ type: types.setPrimaryRecord, payload: data });
}

export default function* watchGetPrimaryRecord() {
  yield takeLatest(types.getPrimaryRecord, getPrimaryRecord);
}
