import * as types from './actionTypes';
import { handleActions } from 'redux-actions';
import { SeniorRecordState } from '../../../../types/store';

const initState: SeniorRecordState = {
  seniorFormDatas: {},
  seniorTimeline: [],
};

export default handleActions(
  new Map([
    [
      types.setSeniorFormData,
      (state: SeniorRecordState, action: any) => {
        return { ...state, seniorFormDatas: { ...action.payload } };
      },
    ],
    [
      types.setSeniorTimeline,
      (state: SeniorRecordState, action: any) => {
        return { ...state, seniorTimeline: [...action.payload] };
      },
    ],
  ]) as { [key: string]: any },
  initState,
);
