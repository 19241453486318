import { takeEvery, select } from 'redux-saga/effects';
import i18next from 'i18next';

import * as types from './actionTypes';

function* updateLanguage() {
  const lang = yield select(state => state.app.lang);
  const isMalaysiaForm = yield select(state => state.app.isMalaysiaForm);

  if (lang === 'en' && isMalaysiaForm) {
    i18next.changeLanguage('my');
    return;
  }
  i18next.changeLanguage(lang);
}

export default function* appSagas() {
  yield takeEvery(types.setLang, updateLanguage);
  yield takeEvery(types.setIsMalaysiaForm, updateLanguage);
}
