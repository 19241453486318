export interface BusinessType {
  individual: '1';
  sole: '2';
  partnership: '3';
  limitedCompany: '4';
  individualIndustrialAndCommerceHousehold: '5';
  enterprise: '6';
}

export const CountryValueMap = {
  hk: 'HK',
  my: 'MY',
  tw: 'TW',
  cn: 'CN',
  sg: 'SG',
}

export interface IdNumberRemarkMap {
  [propName: string]: 'id_card_number_remark';
}

export const BusinessType: BusinessType = {
  individual: '1',
  sole: '2',
  partnership: '3',
  limitedCompany: '4',
  individualIndustrialAndCommerceHousehold: '5',
  enterprise: '6',
};

export const GenderValueMap = {
  male: '1',
  female: '0',
};

export enum IdTypeValueMap {
  ID_CARD = '1',
  PASSPORT = '2',
};

export const DEFAULT = 'default';
export const NationPassportLabelMap = {
  [DEFAULT]: 'passport_number',
}

interface INationIdLabelMap {
  [propName: string]: string;
}

export const NationIdLabelMap: INationIdLabelMap = {
  [CountryValueMap.my]: 'id_card_number_my',
  [CountryValueMap.sg]: 'id_card_number_sg',
  [CountryValueMap.tw]: 'id_card_number',
}

export const IdTypeLabelMap = {
  [IdTypeValueMap.ID_CARD]: NationIdLabelMap,
  [IdTypeValueMap.PASSPORT]: NationPassportLabelMap,
};

export const IdNumberRemarkMap: IdNumberRemarkMap = {
  [IdTypeValueMap.ID_CARD]: 'id_card_number_remark',
}

export interface FormKeyMap {
  firstName: string;
  lastName: string;
  zhFirstName: string;
  zhLastName: string;
  nationality: string;
  idType: string;
  idNumber: string;
  gender: string;
  phone: string;
  email: string;
  contactNum: string;

  companyName: string;
  companyZhName: string;
  companyId: string;
  establishmentDate: string;
  totalCapital: string;
  companyNationality: string;
  billingDescriptor: string;

  shopNationality: string;
}

export const FormKeyMap: FormKeyMap = {
  firstName: 'firstName',
  lastName: 'lastName',
  zhFirstName: 'zhFirstName',
  zhLastName: 'zhLastName',
  nationality: 'nationality',
  idType: 'idType',
  idNumber: 'idNumber',
  gender: 'gender',
  phone: 'phone',
  email: 'email',
  contactNum: 'phone',

  companyName: 'name',
  companyZhName: 'zhName',
  companyId: 'regNumber',
  establishmentDate: 'dateOfEstablishment',
  totalCapital: 'registeredCapital',
  companyNationality: 'regCountry',
  billingDescriptor: 'sl_payment_billing_descriptor_name',

  shopNationality: 'shop_nationality',
};

export const KycStatus = {
  NA: 'not_yet_applied',
  basicPending: 'basic_account_pending',
  basicVerified: 'basic_account_verified',
  basicRejected: 'basic_account_rejected',
  basicReviewed: 'basic_account_reviewed',
  advancePending: 'advanced_account_pending',
  advanceVerified: 'advanced_account_verified',
  advanceRejected: 'advanced_account_rejected',
  advanceFailed: 'advanced_account_failed'
}

export const AvailableRegistrationCountries = [
  'hk', 'cn', 'tw', 'sg', 'us', 'au', 'gb',
  'ph', 'my', 'th', 'jp', 'vn', 'ae',
]
