import { CountryValueMap, FormKeyMap as primaryKeyMap } from '../PrimaryAuthFormArea/constant';

export { BusinessType, GenderValueMap, IdTypeValueMap } from '../PrimaryAuthFormArea/constant';

export interface FormKeyMap extends primaryKeyMap {
  idExpiryDate: 'id_expiry_date';
  dateOfBirth: 'date_of_birth';
  uploadIdImg: 'upload_id_img';
  uploadIdImgFront: 'upload_id_img_front';
  uploadIdImgBack: 'upload_id_img_back';
  liveCountryRegion: 'live_country_region';
  liveState: 'live_state';
  liveCity: 'live_city';
  liveEnCity:'live_en_city',
  liveDistrict: 'live_district';
  liveEnDistrict:'live_en_district',
  livePostcode: 'live_postcode';
  liveAddress: 'live_address';
  liveStreet: 'live_street';
  liveHouseNum: 'live_house_num';

  businessCountryRegion: 'business_country_region';
  businessState: 'business_state';
  businessCity: 'business_city';
  businessEnCity: 'business_en_city',
  businessDistrict: 'business_district',
  businessEnDistrict: 'business_en_district',
  businessPostcode: 'business_postcode';
  businessAddress: 'business_address';
  businessStreet: 'business_street';
  businessHouseNum: 'business_house_num';

  accountRegion: 'account_region';
  accountBankName: 'account_bank_name';
  accountPersonName: 'account_person_name';
  accountBankCode: 'account_bank_code';
  bankAccount: 'bank_account';
  upload6MonthBill: 'upload_6_month_bill';
  introGoodsType: 'intro_goods_type';
  passportImg: 'passport_img';
  shopRegion: 'shop_country_region';
  shopCity: 'shop_city';
  shopEnCity: 'shop_en_city';
  shopDistrict: 'shop_district';
  shopEnDistrict: 'shop_en_district';
  shopState: 'shop_state';
  shopPostcode: 'shop_postcode';
  shopAddress: 'shop_address';
  shopStreet: 'shop_street';
  shopHouseNum: 'shop_house_num';
  shopServices: 'shop_services';
  shopSaleType: 'shop_sale_type';
  expectedMonthlySale: 'gmv';

  // partnership
  proofType: 'proof_type';
  certDate: 'cert_date';
  certBusinessOrCompanyImg: 'cert_business_or_company_img';
  certBankImg: 'cert_bank';

  shareholderList: 'shareholders';

  // specified region fields
  hyphenBankAccount: 'hyphen_bank_account';

  // bank list
  bankList: 'banks';
  bankAccountCurrency: 'bank_account_currency';
  bankAccountType: 'bank_account_type';
  bankSwiftCode: 'bank_swift_code';
  bankAccountName: 'bank_account_name';
  bankBranchName: 'bank_branch_name';
  bankBranchCode: 'bank_branch_code';
  isDefaultBankAccount: 'is_default_bank';

  directorList: 'directors';
  defaultDirector: 'default_director';
}

export const FormKeyMap: FormKeyMap = {
  ...primaryKeyMap,
  idExpiryDate: 'id_expiry_date',
  dateOfBirth: 'date_of_birth',
  uploadIdImg: 'upload_id_img',
  uploadIdImgFront: 'upload_id_img_front',
  uploadIdImgBack: 'upload_id_img_back',
  liveCountryRegion: 'live_country_region',
  liveState: 'live_state',
  liveCity: 'live_city',
  liveEnCity:'live_en_city',
  liveDistrict: 'live_district',
  liveEnDistrict:'live_en_district',
  livePostcode: 'live_postcode',
  liveAddress: 'live_address',
  liveStreet: 'live_street',
  liveHouseNum: 'live_house_num',
  businessCountryRegion: 'business_country_region',
  businessState: 'business_state',
  businessCity: 'business_city',
  businessEnCity: 'business_en_city',
  businessDistrict: 'business_district',
  businessEnDistrict: 'business_en_district',
  businessPostcode: 'business_postcode',
  businessAddress: 'business_address',
  businessStreet: 'business_street',
  businessHouseNum: 'business_house_num',
  accountRegion: 'account_region',
  accountBankName: 'account_bank_name',
  accountPersonName: 'account_person_name',
  accountBankCode: 'account_bank_code',
  bankAccount: 'bank_account',
  upload6MonthBill: 'upload_6_month_bill',
  introGoodsType: 'intro_goods_type',
  passportImg: 'passport_img',
  shopRegion: 'shop_country_region',
  shopState: 'shop_state',
  shopCity: 'shop_city',
  shopEnCity: 'shop_en_city',
  shopDistrict: 'shop_district',
  shopEnDistrict: 'shop_en_district',
  shopPostcode: 'shop_postcode',
  shopAddress: 'shop_address',
  shopStreet: 'shop_street',
  shopHouseNum: 'shop_house_num',
  shopServices: 'shop_services',
  shopSaleType: 'shop_sale_type',
  expectedMonthlySale: 'gmv',

  // partnership
  proofType: 'proof_type',
  certDate: 'cert_date',
  certBusinessOrCompanyImg: 'cert_business_or_company_img',
  certBankImg: 'cert_bank',

  shareholderList: 'shareholders',

  // specified region fields
  hyphenBankAccount: 'hyphen_bank_account',

  bankList: 'banks',
  bankAccountCurrency: 'bank_account_currency',
  bankAccountType: 'bank_account_type',
  bankSwiftCode: 'bank_swift_code',
  bankAccountName: 'bank_account_name',
  bankBranchName: 'bank_branch_name',
  bankBranchCode: 'bank_branch_code',
  isDefaultBankAccount: 'is_default_bank',

  directorList: 'directors',
  defaultDirector: 'default_director',
};
export const tuple = <T extends string[]>(...args: T) => args;

export const ProofValueMap = {
  company: '0',
  business: '1',
};

export const HYPHEN_BANK_FORMAT_TIP = 'xxx-xxxxxxxxx';

export enum BankAccountType {
  PUBLIC = '0',
  PRIVATE = '1',
}

export enum DefaultPayoutCurrencyCountry {
  CNY = 'CN',
  USD = 'HK',
}

export enum DefaultPayoutCountryCurrency {
  CN = 'CNY',
  HK = 'USD',
}

export enum UploadDocumentType {
  BANK_STATEMENT = 'BANK_STATEMENT',
  COMPANY_REGISTRATION_SCREENING = 'COMPANY_REGISTRATION_SCREENING',
  PASSPORT = 'PASSPORT',
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  ID_CARD_BACK = 'ID_CARD_BACK',
  
};

export const ThreeLevelLocationRegion = [CountryValueMap.cn];
