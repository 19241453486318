import _ from 'lodash';

import { Currencies, PageUrls } from './constant';
import { FormDatas } from '../types/common';
import { FormKeyMap } from '@/components/SeniorAuthFormArea/constant';
import { CountryValueMap } from '@/components/PrimaryAuthFormArea/constant'
import { validRequired, validImg } from './validFunc';

export const scrollToFirstError = (prefix, errorInfo) => {
  let firstErrorKey = '';
  let errKyes = Object.keys(errorInfo);
  let element;
  for (let key of errKyes) {
    const status = errorInfo[key] ? errorInfo[key].status : 'valid';
    if (status === 'invalid') {
      firstErrorKey = key;
      break;
    }
  }
  if (firstErrorKey) {
    element = document.getElementById(`${prefix}_${firstErrorKey}`);
    element && element.scrollIntoView({ behavior: 'smooth' });
  }
};

export function convertTimestamp(timestamp) {
  if (!timestamp) return {};
  const date = new Date(timestamp);
  let year, hour, minute, second, month, day;
  year = date.getFullYear();
  hour = date.getHours();
  minute = date.getMinutes();
  second = date.getSeconds();
  month = date.getMonth() + 1;
  day = date.getDate();
  const [dayInEng, monthInEng] = date.toDateString().split(' ');
  return { year, hour, minute, second, month, day, dayInEng, monthInEng };
}

export function getValue(formDatas: FormDatas, key: string) {
  return _.get(formDatas, key);
}

export function getId(idPrefix: string, key: string) {
  return `${idPrefix}_${key}`;
}

// 将20200601或2020/06/01的日期格式转为2020-06-01
export function convertDateFormat(date: string) {
  if (_.isEmpty(date)) return '';
  let result = '';
  let newDate: string | string[] = date.split('/').join('');
  newDate = newDate.split('-').join('');
  if (newDate.length > 8) return date;
  for (let i = 0; i < newDate.length; i++) {
    result += newDate[i];
    if ([3, 5].includes(i)) {
      result += '-';
    }
  }
  return result;
}

export function getHandle(pathname, url: PageUrls[keyof PageUrls], adminV2?: string) {
  const pathArray = pathname.split('/');
  if (adminV2) {
    return (pathArray[1] || '') + '/' + (pathArray[2] || '');
  }
  return pathArray[1] || '';
}

export function getKycStatus(shopInfo): string {
  return shopInfo?.merchant_info?.kyc_status;
}

export function setHyphenBankAccount(
  formDatas: FormDatas,
  key: string
) {
  const inputValue = getValue(formDatas, key) as string;
  if (_.isEmpty(inputValue)) {
    return;
  }

  const splitValueArray = inputValue.split(/-(.*)/);

  let bankBranchCode = splitValueArray[0];
  let backAccount = splitValueArray[1];

  formDatas[FormKeyMap.bankBranchCode] = bankBranchCode;
  formDatas[FormKeyMap.bankAccount] = formDatas[FormKeyMap.accountRegion] === CountryValueMap.sg 
    ? bankBranchCode + backAccount
    : backAccount;

  if (bankBranchCode.length < 3 && _.isUndefined(backAccount)) {
    formDatas[key] = bankBranchCode;
    return;
  }

  if (bankBranchCode.length === 3 && _.isUndefined(backAccount)) {
    formDatas[key] = `${bankBranchCode}-`;
    return;
  }

  if (bankBranchCode.length > 3) {
    bankBranchCode = splitValueArray[0].substring(0, 3);
    backAccount = splitValueArray[0].substring(3) + (splitValueArray[1] || '');
    formDatas[FormKeyMap.bankBranchCode] = bankBranchCode;
    formDatas[FormKeyMap.bankAccount] = backAccount;
    formDatas[key] = `${bankBranchCode}-${backAccount}`;
    return;
  }

  formDatas[key] = `${bankBranchCode}-${backAccount}`;
}

export function getAmountOptions(storeCurrency: string): any {
  let options;

  switch (storeCurrency) {
    case Currencies.HKD:
      options = [
        { key: 'amount_0', label: '< HK$ 100,000', value: '< HK$ 100,000' },
        { key: 'amount_1', label: 'HK$ 100,000-300,000', value: 'HK$ 100,000-300,000' },
        { key: 'amount_2', label: 'HK$ 300,000-600,000', value: 'HK$ 300,000-600,000' },
        { key: 'amount_3', label: '> HK$ 600,000', value: '> HK$ 600,000' },
      ];
      break;
    case Currencies.MYR:
      options = [
        { key: 'amount_0', label: '< RM 2,000', value: '< RM 2,000' },
        { key: 'amount_1', label: 'RM 2,000 - 5,000', value: 'RM 2,000 - 5,000' },
        { key: 'amount_2', label: 'RM 5,001 - 10,000', value: 'RM 5,001 - 10,000' },
        { key: 'amount_3', label: 'RM 10,001 - 20,000', value: 'RM 10,001 - 20,000' },
        { key: 'amount_4', label: 'RM 20,001 - 50,000', value: 'RM 20,001 - 50,000' },
        { key: 'amount_5', label: '> RM 50,000', value: '> RM 50,000' },
      ];
      break;
    case Currencies.USD:
      options = [
        { key: 'amount_0', label: '< US$13,000', value: '< US$13,000' },
        { key: 'amount_1', label: 'US$13,000 - US$39,000', value: 'US$13,000 - US$39,000' },
        { key: 'amount_2', label: 'US$39,000 - US$77,000', value: 'US$39,000 - US$77,000' },
        { key: 'amount_3', label: '> US$77,000', value: '> US$77,000' },
      ];
      break;
    case Currencies.SGD:
      options = [
        { key: 'amount_0', label: '< SGD 17,000', value: '< SGD 17,000' },
        { key: 'amount_1', label: 'SGD 17,000 - 51,000', value: 'SGD 17,000 - 51,000' },
        { key: 'amount_2', label: 'SGD 51,000 - 102,000', value: 'SGD 51,000 - 102,000' },
        { key: 'amount_3', label: '> SGD 102,000', value: '> SGD 102,000' },
      ];
      break;
      case Currencies.TWD:
        options = [
          { key: 'amount_0', label: '<NT$ 350,000', value: '<NT$ 350,000' },
          { key: 'amount_1', label: 'NT$ 350,000-NT$ 1,080,000 ', value: 'NT$ 350,000-NT$ 1,080,000 ' },
          { key: 'amount_2', label: 'NT$ 1,080,000-NT$ 2,150,000', value: 'NT$ 1,080,000-NT$ 2,150,000' },
          { key: 'amount_3', label: '>NT$ 2,150,000', value: '>NT$ 2,150,000' },
        ];
        break;
    default:
      break;
  }
  return options;
}

export function getImgValidRules(
  validRequiredKey: string,
  imgFormValue: any,
  isRecord: boolean | undefined,
  imgRecordValue: any,
): Function[] {
  const rules: Function[] = [validRequired(validRequiredKey)];
  const needValidateImg =
    isRecord && imgRecordValue && imgRecordValue.fileId && imgFormValue && imgFormValue.fileId
    && imgRecordValue.fileId === imgFormValue.fileId;
  needValidateImg && rules.push(validImg(['img_type_err', 'img_size_err']));
  return rules;
}

export function getAdminV2() {
  let version: string = '';
  document.cookie.split(';').forEach((keyValue) => {
    const cookie = keyValue.split('=');
    if (cookie[0].trim() === 'adm_v') {
      version = cookie[1];
    }
  });

  return version;
}
