const countriesMapList = [
    {
        "key": "af",
        "en": "Afghanistan",
        "zh-cn": "阿富汗",
        "zh-hant": "阿富汗"
    },
    {
        "key": "al",
        "en": "Albania",
        "zh-cn": "阿尔巴尼亚",
        "zh-hant": "阿爾巴尼亞"
    },
    {
        "key": "dz",
        "en": "Algeria",
        "zh-cn": "阿尔及利亚",
        "zh-hant": "阿爾及利亞"
    },
    {
        "key": "as",
        "en": "American Samoa",
        "zh-cn": "美属萨摩亚",
        "zh-hant": "美屬薩摩亞"
    },
    {
        "key": "ad",
        "en": "Andorra",
        "zh-cn": "安道尔",
        "zh-hant": "安道爾"
    },
    {
        "key": "ao",
        "en": "Angola",
        "zh-cn": "安哥拉",
        "zh-hant": "安哥拉"
    },
    {
        "key": "ai",
        "en": "Anguilla",
        "zh-cn": "安圭拉",
        "zh-hant": "安圭拉"
    },
    {
        "key": "aq",
        "en": "Antarctica",
        "zh-cn": "南极洲",
        "zh-hant": "南極洲"
    },
    {
        "key": "ag",
        "en": "Antigua and Barbuda",
        "zh-cn": "安提瓜和巴布达",
        "zh-hant": "安提瓜和巴布達"
    },
    {
        "key": "ar",
        "en": "Argentina",
        "zh-cn": "阿根廷",
        "zh-hant": "阿根廷"
    },
    {
        "key": "am",
        "en": "Armenia",
        "zh-cn": "亚美尼亚",
        "zh-hant": "亞美尼亞"
    },
    {
        "key": "aw",
        "en": "Aruba",
        "zh-cn": "阿鲁巴",
        "zh-hant": "阿魯巴"
    },
    {
        "key": "au",
        "en": "Australia",
        "zh-cn": "澳大利亚",
        "zh-hant": "澳大利亞"
    },
    {
        "key": "at",
        "en": "Austria",
        "zh-cn": "奥地利",
        "zh-hant": "奧地利"
    },
    {
        "key": "az",
        "en": "Azerbaijan",
        "zh-cn": "阿塞拜疆",
        "zh-hant": "阿塞拜疆"
    },
    {
        "key": "bs",
        "en": "Bahamas",
        "zh-cn": "巴哈马",
        "zh-hant": "巴哈馬"
    },
    {
        "key": "bh",
        "en": "Bahrain",
        "zh-cn": "巴林",
        "zh-hant": "巴林"
    },
    {
        "key": "bd",
        "en": "Bangladesh",
        "zh-cn": "孟加拉国",
        "zh-hant": "孟加拉國"
    },
    {
        "key": "bb",
        "en": "Barbados",
        "zh-cn": "巴巴多斯",
        "zh-hant": "巴巴多斯"
    },
    {
        "key": "by",
        "en": "Belarus",
        "zh-cn": "白俄罗斯",
        "zh-hant": "白俄羅斯"
    },
    {
        "key": "be",
        "en": "Belgium",
        "zh-cn": "比利时",
        "zh-hant": "比利時"
    },
    {
        "key": "bz",
        "en": "Belize",
        "zh-cn": "伯利兹",
        "zh-hant": "伯利茲"
    },
    {
        "key": "bj",
        "en": "Benin",
        "zh-cn": "贝宁",
        "zh-hant": "貝寧"
    },
    {
        "key": "bm",
        "en": "Bermuda",
        "zh-cn": "百慕大",
        "zh-hant": "百慕大"
    },
    {
        "key": "bt",
        "en": "Bhutan",
        "zh-cn": "不丹",
        "zh-hant": "不丹"
    },
    {
        "key": "bo",
        "en": "Bolivia",
        "zh-cn": "玻利维亚",
        "zh-hant": "玻利維亞"
    },
    {
        "key": "ba",
        "en": "Bosnia and Herzegovina",
        "zh-cn": "波黑",
        "zh-hant": "波黑"
    },
    {
        "key": "bw",
        "en": "Botswana",
        "zh-cn": "博茨瓦纳",
        "zh-hant": "博茨瓦納"
    },
    {
        "key": "bv",
        "en": "Bouvet Island",
        "zh-cn": "布韦岛",
        "zh-hant": "布韋島"
    },
    {
        "key": "br",
        "en": "Brazil",
        "zh-cn": "巴西",
        "zh-hant": "巴西"
    },
    {
        "key": "io",
        "en": "British Indian Ocean Territory",
        "zh-cn": "英属印度洋领地",
        "zh-hant": "英屬印度洋領地"
    },
    {
        "key": "bn",
        "en": "Brunei Darussalam",
        "zh-cn": "文莱",
        "zh-hant": "文萊"
    },
    {
        "key": "bg",
        "en": "Bulgaria",
        "zh-cn": "保加利亚",
        "zh-hant": "保加利亞"
    },
    {
        "key": "bf",
        "en": "Burkina Faso",
        "zh-cn": "布基纳法索",
        "zh-hant": "布基納法索"
    },
    {
        "key": "bi",
        "en": "Burundi",
        "zh-cn": "布隆迪",
        "zh-hant": "布隆迪"
    },
    {
        "key": "kh",
        "en": "Cambodia",
        "zh-cn": "柬埔寨",
        "zh-hant": "柬埔寨"
    },
    {
        "key": "cm",
        "en": "Cameroon",
        "zh-cn": "喀麦隆",
        "zh-hant": "喀麥隆"
    },
    {
        "key": "ca",
        "en": "Canada",
        "zh-cn": "加拿大",
        "zh-hant": "加拿大"
    },
    {
        "key": "cv",
        "en": "Cape Verde",
        "zh-cn": "佛得角",
        "zh-hant": "佛得角"
    },
    {
        "key": "ky",
        "en": "Cayman Islands",
        "zh-cn": "开曼群岛",
        "zh-hant": "開曼群島"
    },
    {
        "key": "cf",
        "en": "Central African Republic",
        "zh-cn": "中非",
        "zh-hant": "中非"
    },
    {
        "key": "td",
        "en": "Chad",
        "zh-cn": "乍得",
        "zh-hant": "乍得"
    },
    {
        "key": "cl",
        "en": "Chile",
        "zh-cn": "智利",
        "zh-hant": "智利"
    },
    {
        "key": "cn",
        "en": "China",
        "zh-cn": "中国",
        "zh-hant": "中國"
    },
    {
        "key": "cx",
        "en": "Christmas Island",
        "zh-cn": "圣诞岛",
        "zh-hant": "聖誕島"
    },
    {
        "key": "cc",
        "en": "Cocos (Keeling) Islands",
        "zh-cn": "科科斯（基林）群岛",
        "zh-hant": "科科斯（基林）群島"
    },
    {
        "key": "co",
        "en": "Colombia",
        "zh-cn": "哥伦比亚",
        "zh-hant": "哥倫比亞"
    },
    {
        "key": "km",
        "en": "Comoros",
        "zh-cn": "科摩罗",
        "zh-hant": "科摩羅"
    },
    {
        "key": "cg",
        "en": "Congo",
        "zh-cn": "刚果（布）",
        "zh-hant": "剛果（布）"
    },
    {
        "key": "cd",
        "en": "Congo, the Democratic Republic of the",
        "zh-cn": "刚果（金）",
        "zh-hant": "剛果（金）"
    },
    {
        "key": "ck",
        "en": "Cook Islands",
        "zh-cn": "库克群岛",
        "zh-hant": "庫克群島"
    },
    {
        "key": "cr",
        "en": "Costa Rica",
        "zh-cn": "哥斯达黎加",
        "zh-hant": "哥斯達黎加"
    },
    {
        "key": "ci",
        "en": "Cote D'Ivoire",
        "zh-cn": "科特迪瓦",
        "zh-hant": "科特迪瓦"
    },
    {
        "key": "hr",
        "en": "Croatia",
        "zh-cn": "克罗地亚",
        "zh-hant": "克羅地亞"
    },
    {
        "key": "cu",
        "en": "Cuba",
        "zh-cn": "古巴",
        "zh-hant": "古巴"
    },
    {
        "key": "cy",
        "en": "Cyprus",
        "zh-cn": "塞浦路斯",
        "zh-hant": "塞浦路斯"
    },
    {
        "key": "cz",
        "en": "Czech Republic",
        "zh-cn": "捷克",
        "zh-hant": "捷克"
    },
    {
        "key": "dk",
        "en": "Denmark",
        "zh-cn": "丹麦",
        "zh-hant": "丹麥"
    },
    {
        "key": "dj",
        "en": "Djibouti",
        "zh-cn": "吉布提",
        "zh-hant": "吉布提"
    },
    {
        "key": "dm",
        "en": "Dominica",
        "zh-cn": "多米尼克",
        "zh-hant": "多米尼克"
    },
    {
        "key": "do",
        "en": "Dominican Republic",
        "zh-cn": "多米尼加",
        "zh-hant": "多米尼加"
    },
    {
        "key": "ec",
        "en": "Ecuador",
        "zh-cn": "厄瓜多尔",
        "zh-hant": "厄瓜多爾"
    },
    {
        "key": "eg",
        "en": "Egypt",
        "zh-cn": "埃及",
        "zh-hant": "埃及"
    },
    {
        "key": "sv",
        "en": "El Salvador",
        "zh-cn": "萨尔瓦多",
        "zh-hant": "薩爾瓦多"
    },
    {
        "key": "gq",
        "en": "Equatorial Guinea",
        "zh-cn": "赤道几内亚",
        "zh-hant": "赤道幾內亞"
    },
    {
        "key": "er",
        "en": "Eritrea",
        "zh-cn": "厄立特里亚",
        "zh-hant": "厄立特里亞"
    },
    {
        "key": "ee",
        "en": "Estonia",
        "zh-cn": "爱沙尼亚",
        "zh-hant": "愛沙尼亞"
    },
    {
        "key": "et",
        "en": "Ethiopia",
        "zh-cn": "埃塞俄比亚",
        "zh-hant": "埃塞俄比亞"
    },
    {
        "key": "fk",
        "en": "Falkland Islands (Malvinas)",
        "zh-cn": "福克兰群岛",
        "zh-hant": "福克蘭群島"
    },
    {
        "key": "fo",
        "en": "Faroe Islands",
        "zh-cn": "法罗群岛",
        "zh-hant": "法羅群島"
    },
    {
        "key": "fj",
        "en": "Fiji",
        "zh-cn": "斐济",
        "zh-hant": "斐濟"
    },
    {
        "key": "fi",
        "en": "Finland",
        "zh-cn": "芬兰",
        "zh-hant": "芬蘭"
    },
    {
        "key": "fr",
        "en": "France",
        "zh-cn": "法国",
        "zh-hant": "法國"
    },
    {
        "key": "gf",
        "en": "French Guiana",
        "zh-cn": "法属圭亚那",
        "zh-hant": "法屬圭亞那"
    },
    {
        "key": "pf",
        "en": "French Polynesia",
        "zh-cn": "法属波利尼西亚",
        "zh-hant": "法屬波利尼西亞"
    },
    {
        "key": "tf",
        "en": "French Southern Territories",
        "zh-cn": "法属南部领地",
        "zh-hant": "法屬南部領地"
    },
    {
        "key": "ga",
        "en": "Gabon",
        "zh-cn": "加蓬",
        "zh-hant": "加蓬"
    },
    {
        "key": "gm",
        "en": "Gambia",
        "zh-cn": "冈比亚",
        "zh-hant": "岡比亞"
    },
    {
        "key": "ge",
        "en": "Georgia",
        "zh-cn": "格鲁吉亚",
        "zh-hant": "格魯吉亞"
    },
    {
        "key": "de",
        "en": "Germany",
        "zh-cn": "德国",
        "zh-hant": "德國"
    },
    {
        "key": "gh",
        "en": "Ghana",
        "zh-cn": "加纳",
        "zh-hant": "加納"
    },
    {
        "key": "gi",
        "en": "Gibraltar",
        "zh-cn": "直布罗陀",
        "zh-hant": "直布羅陀"
    },
    {
        "key": "gr",
        "en": "Greece",
        "zh-cn": "希腊",
        "zh-hant": "希臘"
    },
    {
        "key": "gl",
        "en": "Greenland",
        "zh-cn": "格陵兰",
        "zh-hant": "格陵蘭"
    },
    {
        "key": "gd",
        "en": "Grenada",
        "zh-cn": "格林纳达",
        "zh-hant": "格林納達"
    },
    {
        "key": "gp",
        "en": "Guadeloupe",
        "zh-cn": "瓜德罗普",
        "zh-hant": "瓜德羅普"
    },
    {
        "key": "gu",
        "en": "Guam",
        "zh-cn": "关岛",
        "zh-hant": "關島"
    },
    {
        "key": "gt",
        "en": "Guatemala",
        "zh-cn": "危地马拉",
        "zh-hant": "危地馬拉"
    },
    {
        "key": "gn",
        "en": "Guinea",
        "zh-cn": "几内亚",
        "zh-hant": "幾內亞"
    },
    {
        "key": "gw",
        "en": "Guinea-Bissau",
        "zh-cn": "几内亚比绍",
        "zh-hant": "幾內亞比紹"
    },
    {
        "key": "gy",
        "en": "Guyana",
        "zh-cn": "圭亚那",
        "zh-hant": "圭亞那"
    },
    {
        "key": "ht",
        "en": "Haiti",
        "zh-cn": "海地",
        "zh-hant": "海地"
    },
    {
        "key": "hm",
        "en": "Heard Island and Mcdonald Islands",
        "zh-cn": "赫德岛和麦克唐纳群岛",
        "zh-hant": "赫德島和麥克唐納群島"
    },
    {
        "key": "va",
        "en": "Holy See (Vatican City State)",
        "zh-cn": "梵蒂冈",
        "zh-hant": "梵蒂岡"
    },
    {
        "key": "hn",
        "en": "Honduras",
        "zh-cn": "洪都拉斯",
        "zh-hant": "洪都拉斯"
    },
    {
        "key": "hk",
        "en": "Hong Kong",
        "zh-cn": "香港",
        "zh-hant": "香港"
    },
    {
        "key": "hu",
        "en": "Hungary",
        "zh-cn": "匈牙利",
        "zh-hant": "匈牙利"
    },
    {
        "key": "is",
        "en": "Iceland",
        "zh-cn": "冰岛",
        "zh-hant": "冰島"
    },
    {
        "key": "in",
        "en": "India",
        "zh-cn": "印度",
        "zh-hant": "印度"
    },
    {
        "key": "id",
        "en": "Indonesia",
        "zh-cn": "印尼",
        "zh-hant": "印尼"
    },
    {
        "key": "ir",
        "en": "Iran, Islamic Republic of",
        "zh-cn": "伊朗",
        "zh-hant": "伊朗"
    },
    {
        "key": "iq",
        "en": "Iraq",
        "zh-cn": "伊拉克",
        "zh-hant": "伊拉克"
    },
    {
        "key": "ie",
        "en": "Ireland",
        "zh-cn": "爱尔兰",
        "zh-hant": "愛爾蘭"
    },
    {
        "key": "il",
        "en": "Israel",
        "zh-cn": "以色列",
        "zh-hant": "以色列"
    },
    {
        "key": "it",
        "en": "Italy",
        "zh-cn": "意大利",
        "zh-hant": "意大利"
    },
    {
        "key": "jm",
        "en": "Jamaica",
        "zh-cn": "牙买加",
        "zh-hant": "牙買加"
    },
    {
        "key": "jp",
        "en": "Japan",
        "zh-cn": "日本",
        "zh-hant": "日本"
    },
    {
        "key": "jo",
        "en": "Jordan",
        "zh-cn": "约旦",
        "zh-hant": "約旦"
    },
    {
        "key": "kz",
        "en": "Kazakhstan",
        "zh-cn": "哈萨克斯坦",
        "zh-hant": "哈薩克斯坦"
    },
    {
        "key": "ke",
        "en": "Kenya",
        "zh-cn": "肯尼亚",
        "zh-hant": "肯尼亞"
    },
    {
        "key": "ki",
        "en": "Kiribati",
        "zh-cn": "基里巴斯",
        "zh-hant": "基里巴斯"
    },
    {
        "key": "kp",
        "en": "North Korea",
        "zh-cn": "朝鲜",
        "zh-hant": "朝鮮"
    },
    {
        "key": "kr",
        "en": "South Korea",
        "zh-cn": "韩国",
        "zh-hant": "韓國"
    },
    {
        "key": "kw",
        "en": "Kuwait",
        "zh-cn": "科威特",
        "zh-hant": "科威特"
    },
    {
        "key": "kg",
        "en": "Kyrgyzstan",
        "zh-cn": "吉尔吉斯斯坦",
        "zh-hant": "吉爾吉斯斯坦"
    },
    {
        "key": "la",
        "en": "Lao People's Democratic Republic",
        "zh-cn": "老挝",
        "zh-hant": "老撾"
    },
    {
        "key": "lv",
        "en": "Latvia",
        "zh-cn": "拉脱维亚",
        "zh-hant": "拉脫維亞"
    },
    {
        "key": "lb",
        "en": "Lebanon",
        "zh-cn": "黎巴嫩",
        "zh-hant": "黎巴嫩"
    },
    {
        "key": "ls",
        "en": "Lesotho",
        "zh-cn": "莱索托",
        "zh-hant": "萊索托"
    },
    {
        "key": "lr",
        "en": "Liberia",
        "zh-cn": "利比里亚",
        "zh-hant": "利比里亞"
    },
    {
        "key": "ly",
        "en": "Libya",
        "zh-cn": "利比亚",
        "zh-hant": "利比亞"
    },
    {
        "key": "li",
        "en": "Liechtenstein",
        "zh-cn": "列支敦士登",
        "zh-hant": "列支敦士登"
    },
    {
        "key": "lt",
        "en": "Lithuania",
        "zh-cn": "立陶宛",
        "zh-hant": "立陶宛"
    },
    {
        "key": "lu",
        "en": "Luxembourg",
        "zh-cn": "卢森堡",
        "zh-hant": "盧森堡"
    },
    {
        "key": "mo",
        "en": "Macao",
        "zh-cn": "澳门",
        "zh-hant": "澳門"
    },
    {
        "key": "mg",
        "en": "Madagascar",
        "zh-cn": "马达加斯加",
        "zh-hant": "馬達加斯加"
    },
    {
        "key": "mw",
        "en": "Malawi",
        "zh-cn": "马拉维",
        "zh-hant": "馬拉維"
    },
    {
        "key": "my",
        "en": "Malaysia",
        "zh-cn": "马来西亚",
        "zh-hant": "馬來西亞"
    },
    {
        "key": "mv",
        "en": "Maldives",
        "zh-cn": "马尔代夫",
        "zh-hant": "馬爾代夫"
    },
    {
        "key": "ml",
        "en": "Mali",
        "zh-cn": "马里",
        "zh-hant": "馬里"
    },
    {
        "key": "mt",
        "en": "Malta",
        "zh-cn": "马耳他",
        "zh-hant": "馬耳他"
    },
    {
        "key": "mh",
        "en": "Marshall Islands",
        "zh-cn": "马绍尔群岛",
        "zh-hant": "馬紹爾群島"
    },
    {
        "key": "mq",
        "en": "Martinique",
        "zh-cn": "马提尼克",
        "zh-hant": "馬提尼克"
    },
    {
        "key": "mr",
        "en": "Mauritania",
        "zh-cn": "毛里塔尼亚",
        "zh-hant": "毛里塔尼亞"
    },
    {
        "key": "mu",
        "en": "Mauritius",
        "zh-cn": "毛里求斯",
        "zh-hant": "毛里求斯"
    },
    {
        "key": "yt",
        "en": "Mayotte",
        "zh-cn": "马约特",
        "zh-hant": "馬約特"
    },
    {
        "key": "mx",
        "en": "Mexico",
        "zh-cn": "墨西哥",
        "zh-hant": "墨西哥"
    },
    {
        "key": "fm",
        "en": "Micronesia, Federated States of",
        "zh-cn": "密克罗尼西亚联邦",
        "zh-hant": "密克羅尼西亞聯邦"
    },
    {
        "key": "md",
        "en": "Moldova, Republic of",
        "zh-cn": "摩尔多瓦",
        "zh-hant": "摩爾多瓦"
    },
    {
        "key": "mc",
        "en": "Monaco",
        "zh-cn": "摩纳哥",
        "zh-hant": "摩納哥"
    },
    {
        "key": "mn",
        "en": "Mongolia",
        "zh-cn": "蒙古",
        "zh-hant": "蒙古"
    },
    {
        "key": "ms",
        "en": "Montserrat",
        "zh-cn": "蒙特塞拉特",
        "zh-hant": "蒙特塞拉特"
    },
    {
        "key": "ma",
        "en": "Morocco",
        "zh-cn": "摩洛哥",
        "zh-hant": "摩洛哥"
    },
    {
        "key": "mz",
        "en": "Mozambique",
        "zh-cn": "莫桑比克",
        "zh-hant": "莫桑比克"
    },
    {
        "key": "mm",
        "en": "Myanmar",
        "zh-cn": "缅甸",
        "zh-hant": "緬甸"
    },
    {
        "key": "na",
        "en": "Namibia",
        "zh-cn": "纳米比亚",
        "zh-hant": "納米比亞"
    },
    {
        "key": "nr",
        "en": "Nauru",
        "zh-cn": "瑙鲁",
        "zh-hant": "瑙魯"
    },
    {
        "key": "np",
        "en": "Nepal",
        "zh-cn": "尼泊尔",
        "zh-hant": "尼泊爾"
    },
    {
        "key": "nl",
        "en": "Netherlands",
        "zh-cn": "荷兰",
        "zh-hant": "荷蘭"
    },
    {
        "key": "nc",
        "en": "New Caledonia",
        "zh-cn": "新喀里多尼亚",
        "zh-hant": "新喀裡多尼亞"
    },
    {
        "key": "nz",
        "en": "New Zealand",
        "zh-cn": "新西兰",
        "zh-hant": "新西蘭"
    },
    {
        "key": "ni",
        "en": "Nicaragua",
        "zh-cn": "尼加拉瓜",
        "zh-hant": "尼加拉瓜"
    },
    {
        "key": "ne",
        "en": "Niger",
        "zh-cn": "尼日尔",
        "zh-hant": "尼日爾"
    },
    {
        "key": "ng",
        "en": "Nigeria",
        "zh-cn": "尼日利亚",
        "zh-hant": "尼日利亞"
    },
    {
        "key": "nu",
        "en": "Niue",
        "zh-cn": "纽埃",
        "zh-hant": "紐埃"
    },
    {
        "key": "nf",
        "en": "Norfolk Island",
        "zh-cn": "诺福克岛",
        "zh-hant": "諾福克島"
    },
    {
        "key": "mk",
        "en": "North Macedonia, Republic of",
        "zh-cn": "北马其顿",
        "zh-hant": "北馬其頓"
    },
    {
        "key": "mp",
        "en": "Northern Mariana Islands",
        "zh-cn": "北马里亚纳群岛",
        "zh-hant": "北馬里亞納群島"
    },
    {
        "key": "no",
        "en": "Norway",
        "zh-cn": "挪威",
        "zh-hant": "挪威"
    },
    {
        "key": "om",
        "en": "Oman",
        "zh-cn": "阿曼",
        "zh-hant": "阿曼"
    },
    {
        "key": "pk",
        "en": "Pakistan",
        "zh-cn": "巴基斯坦",
        "zh-hant": "巴基斯坦"
    },
    {
        "key": "pw",
        "en": "Palau",
        "zh-cn": "帕劳",
        "zh-hant": "帕勞"
    },
    {
        "key": "ps",
        "en": "Palestinian Territory, Occupied",
        "zh-cn": "巴勒斯坦",
        "zh-hant": "巴勒斯坦"
    },
    {
        "key": "pa",
        "en": "Panama",
        "zh-cn": "巴拿马",
        "zh-hant": "巴拿馬"
    },
    {
        "key": "pg",
        "en": "Papua New Guinea",
        "zh-cn": "巴布亚新几内亚",
        "zh-hant": "巴布亞新幾內亞"
    },
    {
        "key": "py",
        "en": "Paraguay",
        "zh-cn": "巴拉圭",
        "zh-hant": "巴拉圭"
    },
    {
        "key": "pe",
        "en": "Peru",
        "zh-cn": "秘鲁",
        "zh-hant": "秘魯"
    },
    {
        "key": "ph",
        "en": "Philippines",
        "zh-cn": "菲律宾",
        "zh-hant": "菲律賓"
    },
    {
        "key": "pn",
        "en": "Pitcairn",
        "zh-cn": "皮特凯恩群岛",
        "zh-hant": "皮特凱恩群島"
    },
    {
        "key": "pl",
        "en": "Poland",
        "zh-cn": "波兰",
        "zh-hant": "波蘭"
    },
    {
        "key": "pt",
        "en": "Portugal",
        "zh-cn": "葡萄牙",
        "zh-hant": "葡萄牙"
    },
    {
        "key": "pr",
        "en": "Puerto Rico",
        "zh-cn": "波多黎各",
        "zh-hant": "波多黎各"
    },
    {
        "key": "qa",
        "en": "Qatar",
        "zh-cn": "卡塔尔",
        "zh-hant": "卡塔爾"
    },
    {
        "key": "re",
        "en": "Reunion",
        "zh-cn": "留尼汪",
        "zh-hant": "留尼汪"
    },
    {
        "key": "ro",
        "en": "Romania",
        "zh-cn": "罗马尼亚",
        "zh-hant": "羅馬尼亞"
    },
    {
        "key": "ru",
        "en": "Russia",
        "zh-cn": "俄罗斯",
        "zh-hant": "俄羅斯"
    },
    {
        "key": "rw",
        "en": "Rwanda",
        "zh-cn": "卢旺达",
        "zh-hant": "盧旺達"
    },
    {
        "key": "sh",
        "en": "Saint Helena",
        "zh-cn": "圣赫勒拿、阿森松和特里斯坦-达库尼亚",
        "zh-hant": "聖赫勒拿、阿森松和特里斯坦-達庫尼亞"
    },
    {
        "key": "kn",
        "en": "Saint Kitts and Nevis",
        "zh-cn": "圣基茨和尼维斯",
        "zh-hant": "聖基茨和尼維斯"
    },
    {
        "key": "lc",
        "en": "Saint Lucia",
        "zh-cn": "圣卢西亚",
        "zh-hant": "聖盧西亞"
    },
    {
        "key": "pm",
        "en": "Saint Pierre and Miquelon",
        "zh-cn": "圣皮埃尔和密克隆",
        "zh-hant": "聖皮埃爾和密克隆"
    },
    {
        "key": "vc",
        "en": "Saint Vincent and the Grenadines",
        "zh-cn": "圣文森特和格林纳丁斯",
        "zh-hant": "聖文森特和格林納丁斯"
    },
    {
        "key": "ws",
        "en": "Samoa",
        "zh-cn": "萨摩亚",
        "zh-hant": "薩摩亞"
    },
    {
        "key": "sm",
        "en": "San Marino",
        "zh-cn": "圣马力诺",
        "zh-hant": "聖馬力諾"
    },
    {
        "key": "st",
        "en": "Sao Tome and Principe",
        "zh-cn": "圣多美和普林西比",
        "zh-hant": "聖多美和普林西比"
    },
    {
        "key": "sa",
        "en": "Saudi Arabia",
        "zh-cn": "沙特阿拉伯",
        "zh-hant": "沙特阿拉伯"
    },
    {
        "key": "sn",
        "en": "Senegal",
        "zh-cn": "塞内加尔",
        "zh-hant": "塞內加爾"
    },
    {
        "key": "sc",
        "en": "Seychelles",
        "zh-cn": "塞舌尔",
        "zh-hant": "塞舌爾"
    },
    {
        "key": "sl",
        "en": "Sierra Leone",
        "zh-cn": "塞拉利昂",
        "zh-hant": "塞拉利昂"
    },
    {
        "key": "sg",
        "en": "Singapore",
        "zh-cn": "新加坡",
        "zh-hant": "新加坡"
    },
    {
        "key": "sk",
        "en": "Slovakia",
        "zh-cn": "斯洛伐克",
        "zh-hant": "斯洛伐克"
    },
    {
        "key": "si",
        "en": "Slovenia",
        "zh-cn": "斯洛文尼亚",
        "zh-hant": "斯洛文尼亞"
    },
    {
        "key": "sb",
        "en": "Solomon Islands",
        "zh-cn": "所罗门群岛",
        "zh-hant": "所羅門群島"
    },
    {
        "key": "so",
        "en": "Somalia",
        "zh-cn": "索马里",
        "zh-hant": "索馬里"
    },
    {
        "key": "za",
        "en": "South Africa",
        "zh-cn": "南非",
        "zh-hant": "南非"
    },
    {
        "key": "gs",
        "en": "South Georgia and the South Sandwich Islands",
        "zh-cn": "南乔治亚和南桑威奇群岛",
        "zh-hant": "南喬治亞和南桑威奇群島"
    },
    {
        "key": "es",
        "en": "Spain",
        "zh-cn": "西班牙",
        "zh-hant": "西班牙"
    },
    {
        "key": "lk",
        "en": "Sri Lanka",
        "zh-cn": "斯里兰卡",
        "zh-hant": "斯里蘭卡"
    },
    {
        "key": "sd",
        "en": "Sudan",
        "zh-cn": "苏丹",
        "zh-hant": "蘇丹"
    },
    {
        "key": "sr",
        "en": "Suriname",
        "zh-cn": "苏里南",
        "zh-hant": "蘇里南"
    },
    {
        "key": "sj",
        "en": "Svalbard and Jan Mayen",
        "zh-cn": "斯瓦尔巴和扬马延",
        "zh-hant": "斯瓦爾巴和揚馬延"
    },
    {
        "key": "sz",
        "en": "Eswatini",
        "zh-cn": "斯威士兰",
        "zh-hant": "斯威士蘭"
    },
    {
        "key": "se",
        "en": "Sweden",
        "zh-cn": "瑞典",
        "zh-hant": "瑞典"
    },
    {
        "key": "ch",
        "en": "Switzerland",
        "zh-cn": "瑞士",
        "zh-hant": "瑞士"
    },
    {
        "key": "sy",
        "en": "Syrian Arab Republic",
        "zh-cn": "叙利亚",
        "zh-hant": "敘利亞"
    },
    {
        "key": "tw",
        "en": "Taiwan",
        "zh-cn": "台湾",
        "zh-hant": "台灣"
    },
    {
        "key": "tj",
        "en": "Tajikistan",
        "zh-cn": "塔吉克斯坦",
        "zh-hant": "塔吉克斯坦"
    },
    {
        "key": "tz",
        "en": "Tanzania, United Republic of",
        "zh-cn": "坦桑尼亚",
        "zh-hant": "坦桑尼亞"
    },
    {
        "key": "th",
        "en": "Thailand",
        "zh-cn": "泰国",
        "zh-hant": "泰國"
    },
    {
        "key": "tl",
        "en": "Timor-Leste",
        "zh-cn": "东帝汶",
        "zh-hant": "東帝汶"
    },
    {
        "key": "tg",
        "en": "Togo",
        "zh-cn": "多哥",
        "zh-hant": "多哥"
    },
    {
        "key": "tk",
        "en": "Tokelau",
        "zh-cn": "托克劳",
        "zh-hant": "托克勞"
    },
    {
        "key": "to",
        "en": "Tonga",
        "zh-cn": "汤加",
        "zh-hant": "湯加"
    },
    {
        "key": "tt",
        "en": "Trinidad and Tobago",
        "zh-cn": "特立尼达和多巴哥",
        "zh-hant": "特立尼達和多巴哥"
    },
    {
        "key": "tn",
        "en": "Tunisia",
        "zh-cn": "突尼斯",
        "zh-hant": "突尼斯"
    },
    {
        "key": "tr",
        "en": "Turkey",
        "zh-cn": "土耳其",
        "zh-hant": "土耳其"
    },
    {
        "key": "tm",
        "en": "Turkmenistan",
        "zh-cn": "土库曼斯坦",
        "zh-hant": "土庫曼斯坦"
    },
    {
        "key": "tc",
        "en": "Turks and Caicos Islands",
        "zh-cn": "特克斯和凯科斯群岛",
        "zh-hant": "特克斯和凱科斯群島"
    },
    {
        "key": "tv",
        "en": "Tuvalu",
        "zh-cn": "图瓦卢",
        "zh-hant": "圖瓦盧"
    },
    {
        "key": "ug",
        "en": "Uganda",
        "zh-cn": "乌干达",
        "zh-hant": "烏干達"
    },
    {
        "key": "ua",
        "en": "Ukraine",
        "zh-cn": "乌克兰",
        "zh-hant": "烏克蘭"
    },
    {
        "key": "ae",
        "en": "United Arab Emirates",
        "zh-cn": "阿联酋",
        "zh-hant": "阿聯酋"
    },
    {
        "key": "gb",
        "en": "United Kingdom",
        "zh-cn": "英国",
        "zh-hant": "英國"
    },
    {
        "key": "us",
        "en": "United States of America",
        "zh-cn": "美国",
        "zh-hant": "美國"
    },
    {
        "key": "um",
        "en": "United States Minor Outlying Islands",
        "zh-cn": "美国本土外小岛屿",
        "zh-hant": "美國本土外小島嶼"
    },
    {
        "key": "uy",
        "en": "Uruguay",
        "zh-cn": "乌拉圭",
        "zh-hant": "烏拉圭"
    },
    {
        "key": "uz",
        "en": "Uzbekistan",
        "zh-cn": "乌兹别克斯坦",
        "zh-hant": "烏茲別克斯坦"
    },
    {
        "key": "vu",
        "en": "Vanuatu",
        "zh-cn": "瓦努阿图",
        "zh-hant": "瓦努阿圖"
    },
    {
        "key": "ve",
        "en": "Venezuela",
        "zh-cn": "委内瑞拉",
        "zh-hant": "委內瑞拉"
    },
    {
        "key": "vn",
        "en": "Vietnam",
        "zh-cn": "越南",
        "zh-hant": "越南"
    },
    {
        "key": "vg",
        "en": "Virgin Islands, British",
        "zh-cn": "英属维尔京群岛",
        "zh-hant": "英屬維爾京群島"
    },
    {
        "key": "vi",
        "en": "Virgin Islands, U.S.",
        "zh-cn": "美属维尔京群岛",
        "zh-hant": "美屬維爾京群島"
    },
    {
        "key": "wf",
        "en": "Wallis and Futuna",
        "zh-cn": "瓦利斯和富图纳",
        "zh-hant": "瓦利斯和富圖納"
    },
    {
        "key": "eh",
        "en": "Western Sahara",
        "zh-cn": "阿拉伯撒哈拉民主共和国",
        "zh-hant": "阿拉伯撒哈拉民主共和國"
    },
    {
        "key": "ye",
        "en": "Yemen",
        "zh-cn": "也门",
        "zh-hant": "也門"
    },
    {
        "key": "zm",
        "en": "Zambia",
        "zh-cn": "赞比亚",
        "zh-hant": "贊比亞"
    },
    {
        "key": "zw",
        "en": "Zimbabwe",
        "zh-cn": "津巴布韦",
        "zh-hant": "津巴布韋"
    },
    {
        "key": "ax",
        "en": "Åland Islands",
        "zh-cn": "奥兰",
        "zh-hant": "奧蘭"
    },
    {
        "key": "bq",
        "en": "Bonaire, Sint Eustatius and Saba",
        "zh-cn": "荷兰加勒比区",
        "zh-hant": "荷蘭加勒比區"
    },
    {
        "key": "cw",
        "en": "Curaçao",
        "zh-cn": "库拉索",
        "zh-hant": "庫拉索"
    },
    {
        "key": "gg",
        "en": "Guernsey",
        "zh-cn": "根西",
        "zh-hant": "根西"
    },
    {
        "key": "im",
        "en": "Isle of Man",
        "zh-cn": "马恩岛",
        "zh-hant": "馬恩島"
    },
    {
        "key": "je",
        "en": "Jersey",
        "zh-cn": "泽西",
        "zh-hant": "澤西"
    },
    {
        "key": "me",
        "en": "Montenegro",
        "zh-cn": "黑山",
        "zh-hant": "黑山"
    },
    {
        "key": "bl",
        "en": "Saint Barthélemy",
        "zh-cn": "圣巴泰勒米",
        "zh-hant": "聖巴泰勒米"
    },
    {
        "key": "mf",
        "en": "Saint Martin (French part)",
        "zh-cn": "法属圣马丁",
        "zh-hant": "法屬聖馬丁"
    },
    {
        "key": "rs",
        "en": "Serbia",
        "zh-cn": "塞尔维亚",
        "zh-hant": "塞爾維亞"
    },
    {
        "key": "sx",
        "en": "Sint Maarten (Dutch part)",
        "zh-cn": "荷属圣马丁",
        "zh-hant": "荷屬聖馬丁"
    },
    {
        "key": "ss",
        "en": "South Sudan",
        "zh-cn": "南苏丹",
        "zh-hant": "南蘇丹"
    },
    {
        "key": "xk",
        "en": "Kosovo",
        "zh-cn": "科索沃",
        "zh-hant": "科索沃"
    }
]
export default countriesMapList
