const  TW_City_District_Map = 
{
    "Taipei City":"台北市",
    "Keelung City":"基隆市",
    "New Taipei City":"新北市",
    "Yilan County":"宜蘭縣",
    "Hsinchu City":"新竹市",
    "Hsinchu County":"新竹縣",
    "Taoyuan City":"桃園市",
    "Miaoli County":"苗栗縣",
    "Taichung City":"台中市",
    "Changhua County":"彰化縣",
    "Nantou County":"南投縣",
    "Chiayi City":"嘉義市",
    "Chiayi County":"嘉義縣",
    "Yunlin County":"雲林縣",
    "Tainan City":"台南市",
    "Kaohsiung City":"高雄市",
    "Pingtung County":"屏東縣",
    "Taitung County":"台東縣",
    "Hualien County":"花蓮縣",
    "Penghu County":"澎湖縣",
    "Kinmen County":"金門縣",
    "Lienchiang County":"連江縣",
    "Zhongzheng District":"中正區",
    "Datong District":"大同區",
    "Zhongshan District":"中山區",
    "Songshan District":"松山區",
    "Da'an District":"大安區",
    "Wanhua District":"萬華區",
    "Xinyi District":"信義區",
    "Shilin District":"士林區",
    "Beitou District":"北投區",
    "Neihu District":"內湖區",
    "Nangang District":"南港區",
    "Wenshan District":"文山區",
    "Ren'ai District":"仁愛區",
    "Anle District":"安樂區",
    "Nuannuan District":"暖暖區",
    "Qidu District":"七堵區",
    "Wanli District":"萬里區",
    "Jinshan District":"金山區",
    "Banqiao District":"板橋區",
    "Xizhi District":"汐止區",
    "Shenkeng District":"深坑區",
    "Shiding District":"石碇區",
    "Ruifang District":"瑞芳區",
    "Pingxi District":"平溪區",
    "Shuangxi District":"雙溪區",
    "Gongliao District":"貢寮區",
    "Xindian District":"新店區",
    "Pinglin District":"坪林區",
    "Wulai District":"烏來區",
    "Yonghe District":"永和區",
    "Zhonghe District":"中和區",
    "Tucheng District":"土城區",
    "Sanxia District":"三峽區",
    "Shulin District":"樹林區",
    "Yingge District":"鶯歌區",
    "Sanchong District":"三重區",
    "Xinzhuang District":"新莊區",
    "Taishan District":"泰山區",
    "Linkou District":"林口區",
    "Luzhou District":"蘆洲區",
    "Wugu District":"五股區",
    "Bali District":"八里區",
    "Tamsui District":"淡水區",
    "Sanzhi District":"三芝區",
    "Shimen District":"石門區",
    "Yilan City":"宜蘭市",
    "Toucheng Township":"頭城鎮",
    "Jiaoxi Township":"礁溪鄉",
    "Zhuangwei Township":"壯圍鄉",
    "Yuanshan Township":"員山鄉",
    "Luodong Township":"羅東鎮",
    "Sanxing Township":"三星鄉",
    "Datong Township":"大同鄉",
    "Wujie Township":"五結鄉",
    "Dongshan Township":"冬山鄉",
    "Su'ao Township":"蘇澳鎮",
    "Nan'ao Township":"南澳鄉",
    "North District":"北區",
    "East District":"東區",
    "Xiangshan District":"香山區",
    "Zhubei City":"竹北市",
    "Hukou Township":"湖口鄉",
    "Xinfeng Township":"新豐鄉",
    "Xinpu Township":"新埔鎮",
    "Guanxi Township":"關西鎮",
    "Qionglin Township":"芎林鄉",
    "Baoshan Township":"寶山鄉",
    "Zhudong Township":"竹東鎮",
    "Wufeng Township":"五峰鄉",
    "Hengshan Township":"橫山鄉",
    "Jianshi Township":"尖石鄉",
    "Beipu Township":"北埔鄉",
    "Emei Township":"峨眉鄉",
    "Zhongli District":"中壢區",
    "Pingzhen District":"平鎮區",
    "Longtan District":"龍潭區",
    "Yangmei District":"楊梅區",
    "Xinwu District":"新屋區",
    "Guanyin District":"觀音區",
    "Taoyuan District":"桃源區",
    "Guishan District":"龜山區",
    "Bade District":"八德區",
    "Daxi District":"大溪區",
    "Fuxing District":"復興區",
    "Dayuan District":"大園區",
    "Luzhu District":"路竹區",
    "Zhunan Township":"竹南鎮",
    "Toufen Township":"頭份鎮",
    "Sanwan Township":"三灣鄉",
    "Nanzhuang Township":"南庄鄉",
    "Shitan Township":"獅潭鄉",
    "Houlong Township":"後龍鎮",
    "Tongxiao Township":"通霄鎮",
    "Yuanli Township":"苑裡鎮",
    "Miaoli City":"苗栗市",
    "Zaoqiao Township":"造橋鄉",
    "Touwu Township":"頭屋鄉",
    "Gongguan Township":"公館鄉",
    "Dahu Township":"大湖鄉",
    "Tai'an Township":"泰安鄉",
    "Tongluo Township":"銅鑼鄉",
    "Sanyi Township":"三義鄉",
    "Xihu Township":"溪湖鎮",
    "Zhuolan Township":"卓蘭鎮",
    "Central District":"中區",
    "South District":"南區",
    "West District":"西區",
    "Beitun District":"北屯區",
    "Xitun District":"西屯區",
    "Nantun District":"南屯區",
    "Taiping District":"太平區",
    "Dali District":"大里區",
    "Wufeng District":"霧峰區",
    "Wuri District":"烏日區",
    "Fengyuan District":"豐原區",
    "Houli District":"后里區",
    "Shigang District":"石岡區",
    "Dongshi District":"東勢區",
    "Heping District":"和平區",
    "Xinshe District":"新社區",
    "Tanzi District":"潭子區",
    "Daya District":"大雅區",
    "Shengang District":"神岡區",
    "Dadu District":"大肚區",
    "Shalu District":"沙鹿區",
    "Longjing District":"龍井區",
    "Wuqi District":"梧棲區",
    "Qingshui District":"清水區",
    "Dajia District":"大甲區",
    "Waipu District":"外埔區",
    "Changhua City":"彰化市",
    "Fenyuan Township":"芬園鄉",
    "Huatan Township":"花壇鄉",
    "Xiushui Township":"秀水鄉",
    "Lukang Township":"鹿港鎮",
    "Fuxing Township":"福興鄉",
    "Xianxi Township":"線西鄉",
    "Hemei Township":"和美鎮",
    "Shengang Township":"伸港鄉",
    "Yuanlin Township":"員林鎮",
    "Shetou Township":"社頭鄉",
    "Yongjing Township":"永靖鄉",
    "Puxin Township":"埔心鄉",
    "Dacun Township":"大村鄉",
    "Puyan Township":"埔鹽鄉",
    "Tianzhong Township":"田中鎮",
    "Beidou Township":"北斗鎮",
    "Tianwei Township":"田尾鄉",
    "Pitou Township":"埤頭鄉",
    "Xizhou Township":"溪州鄉",
    "Zhutang Township":"竹塘鄉",
    "Erlin Township":"二林鎮",
    "Dacheng Township":"大城鄉",
    "Fangyuan Township":"芳苑鄉",
    "Ershui Township":"二水鄉",
    "Nantou City":"南投市",
    "Zhongliao Township":"中寮鄉",
    "Caotun Township":"草屯鎮",
    "Guoxing Township":"國姓鄉",
    "Puli Township":"埔里鎮",
    "Ren'ai Township":"仁愛鄉",
    "Mingjian Township":"名間鄉",
    "Jiji Township":"集集鎮",
    "Shuili Township":"水里鄉",
    "Yuchi Township":"魚池鄉",
    "Xinyi Township":"信義鄉",
    "Zhushan Township":"竹山鎮",
    "Lugu Township":"鹿谷鄉",
    "Fanlu Township":"番路鄉",
    "Meishan Township":"梅山鄉",
    "Zhuqi Township":"竹崎鄉",
    "Alishan Township":"阿里山",
    "Zhongpu Township":"中埔鄉",
    "Dapu Township":"大埔鄉",
    "Shuishang Township":"水上鄉",
    "Lucao Township":"鹿草鄉",
    "Taibao City":"太保市",
    "Puzi City":"朴子市",
    "Dongshi Township":"東勢鄉",
    "Liujiao Township":"六腳鄉",
    "Xingang Township":"新港鄉",
    "Minxiong Township":"民雄鄉",
    "Dalin Township":"大林鎮",
    "Xikou Township":"溪口鄉",
    "Yizhu Township":"義竹鄉",
    "Budai Township":"布袋鎮",
    "Dounan Township":"斗南鎮",
    "Dapi Township":"大埤鄉",
    "Huwei Township":"虎尾鎮",
    "Tuku Township":"土庫鎮",
    "Baozhong Township":"褒忠鄉",
    "Taixi Township":"台西鄉",
    "Lunbei Township":"崙背鄉",
    "Mailiao Township":"麥寮鄉",
    "Douliu City":"斗六市",
    "Linnei Township":"林內鄉",
    "Gukeng Township":"古坑鄉",
    "Citong Township":"莿桐鄉",
    "Xiluo Township":"西螺鎮",
    "Erlun Township":"二崙鄉",
    "Beigang Township":"北港鎮",
    "Shuilin Township":"水林鄉",
    "Kouhu Township":"口湖鄉",
    "Sihu Township":"四湖鄉",
    "Yuanchang Township":"元長鄉",
    "West Central District":"中西區",
    "Anping District":"安平區",
    "Annan District":"安南區",
    "Yongkang District":"永康區",
    "Guiren District":"歸仁區",
    "Xinhua District":"新化區",
    "Zuozhen District":"左鎮區",
    "Yujing District":"玉井區",
    "Nanxi District":"楠西區",
    "Nanhua District":"南化區",
    "Rende District":"仁德區",
    "Guanmiao District":"關廟區",
    "Longqi District":"龍崎區",
    "Guantian District":"官田區",
    "Madou District":"麻豆區",
    "Jiali District":"佳里區",
    "Xigang District":"西港區",
    "Qigu District":"七股區",
    "Jiangjun District":"將軍區",
    "Xuejia District":"學甲區",
    "Beimen District":"北門區",
    "Xinying District":"新營區",
    "Houbi District":"後壁區",
    "Baihe District":"白河區",
    "Dongshan District":"東山區",
    "Liujia District":"六甲區",
    "Xiaying District":"下營區",
    "Liuying District":"柳營區",
    "Yanshui District":"鹽水區",
    "Shanhua District":"善化區",
    "Danei District":"大內區",
    "Shanshang District":"山上區",
    "Xinshi District":"新市區",
    "Anding District":"安定區",
    "Xinxing District":"新興區",
    "Qianjin District":"前金區",
    "Lingya District":"苓雅區",
    "Yancheng District":"鹽埕區",
    "Gushan District":"鼓山區",
    "Qijin District":"旗津區",
    "Qianzhen District":"前鎮區",
    "Sanmin District":"三民區",
    "Nanzi District":"楠梓區",
    "Xiaogang District":"小港區",
    "Zuoying District":"左營區",
    "Renwu District":"仁武區",
    "Dashe District":"大社區",
    "Gangshan District":"岡山區",
    "Alian District":"阿蓮區",
    "Tianliao District":"田寮區",
    "Yanchao District":"燕巢區",
    "Qiaotou District":"橋頭區",
    "Ziguan District":"梓官區",
    "Mituo District":"彌陀區",
    "Yong'an District":"永安區",
    "Hunei District":"湖內區",
    "Fengshan District":"鳳山區",
    "Daliao District":"大寮區",
    "Linyuan District":"林園區",
    "Niaosong District":"鳥松區",
    "Dashu District":"大樹區",
    "Qishan District":"旗山區",
    "Meinong District":"美濃區",
    "Liugui District":"六龜區",
    "Neimen District":"內門區",
    "Shanlin District":"杉林區",
    "Jiaxian District":"甲仙區",
    "Namaxia District":"那瑪夏",
    "Maolin District":"茂林區",
    "Qieding District":"茄萣區",
    "Pingtung City":"屏東市",
    "Sandimen Township":"三地門",
    "Wutai Township":"霧臺鄉",
    "Majia Township":"瑪家鄉",
    "Jiuru Township":"九如鄉",
    "Ligang Township":"里港鄉",
    "Gaoshu Township":"高樹鄉",
    "Yanpu Township":"鹽埔鄉",
    "Changzhi Township":"長治鄉",
    "Linluo Township":"麟洛鄉",
    "Zhutian Township":"竹田鄉",
    "Neipu Township":"內埔鄉",
    "Wandan Township":"萬丹鄉",
    "Chaozhou Township":"潮州鎮",
    "Taiwu Township":"泰武鄉",
    "Laiyi Township":"來義鄉",
    "Wanluan Township":"萬巒鄉",
    "Kanding Township":"崁頂鄉",
    "Xinpi Township":"新埤鄉",
    "Nanzhou Township":"南州鄉",
    "Linbian Township":"林邊鄉",
    "Donggang Township":"東港鎮",
    "Jiadong Township":"佳冬鄉",
    "Xinyuan Township":"新園鄉",
    "Fangliao Township":"枋寮鄉",
    "Fangshan Township":"枋山鄉",
    "Chunri Township":"春日鄉",
    "Shizi Township":"獅子鄉",
    "Checheng Township":"車城鄉",
    "Mudan Township":"牡丹鄉",
    "Hengchun Township":"恆春鎮",
    "Manzhou Township":"滿州鄉",
    "Liuqiu Township":"琉球鄉",
    "Taitung City":"台東市",
    "Yanping Township":"延平鄉",
    "Beinan Township":"卑南鄉",
    "Luye Township":"鹿野鄉",
    "Guanshan Township":"關山鎮",
    "Haiduan Township":"海端鄉",
    "Chishang Township":"池上鄉",
    "Donghe Township":"東河鄉",
    "Chenggong Township":"成功鎮",
    "Changbin Township":"長濱鄉",
    "Taimali Township":"太麻里",
    "Jinfeng Township":"金峰鄉",
    "Dawu Township":"大武鄉",
    "Daren Township":"達仁鄉",
    "Lüdao Township":"綠島鄉",
    "Lanyu Township":"蘭嶼鄉",
    "Hualien City":"花蓮市",
    "Xincheng Township":"新城鄉",
    "Xiulin Township":"秀林鄉",
    "Ji'an Township":"吉安鄉",
    "Shoufeng Township":"壽豐鄉",
    "Fenglin Township":"鳳林鎮",
    "Guangfu Township":"光復鄉",
    "Fengbin Township":"豐濱鄉",
    "Ruisui Township":"瑞穗鄉",
    "Wanrong Township":"萬榮鄉",
    "Yuli Township":"玉里鎮",
    "Zhuoxi Township":"卓溪鄉",
    "Fuli Township":"富里鄉",
    "Magong City":"馬公市",
    "Xiyu Township":"西嶼鄉",
    "Wang'an Township":"望安鄉",
    "Qimei Township":"七美鄉",
    "Baisha Township":"白沙鄉",
    "Huxi Township":"湖西鄉",
    "Jinsha Township":"金沙鎮",
    "Jinhu Township":"金湖鎮",
    "Jinning Township":"金寧鄉",
    "Jincheng Township":"金城鎮",
    "Lieyu Township":"烈嶼鄉",
    "Wuqiu Township":"烏坵鄉",
    "Nangan Township":"南竿鄉",
    "Beigan Township":"北竿鄉",
    "Juguang Township":"莒光鄉",
    "Dongyin Township":"東引鄉"
}
export default TW_City_District_Map