import * as types from './actionTypes';
import { handleActions } from 'redux-actions';
import { BasicState } from '../../types/store';

interface Action {
  payload: any[];
}

const initState: BasicState = {
  region: [],
  bank: [],
  shopInfo: {},
  locations: [],
};

export default handleActions(
  new Map([
    [types.setRegion, (state: BasicState, action: Action) => ({ ...state, region: action.payload })],
    [types.setBank, (state, action) => ({ ...state, bank: action.payload })],
    [types.setBankBranches, (state, action) => ({ ...state, bankBranches: action.payload })],
    [types.setShopInfo, (state, action) => ({ ...state, shopInfo: action.payload })],
    [types.setLocations, (state, action) => ({ ...state, locations: action.payload })],
  ]) as { [key: string]: any },
  initState,
);
