import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTrans from '../assets/locales/en/translation.json';
import enMyTrans from '../assets/locales/my/translation.json';
import zhHantTrans from '../assets/locales/zh-hant/translation.json';
import zhCnTrans from '../assets/locales/zh-cn/translation.json';
import viTrans from '../assets/locales/vi/translation.json';
import thTrans from '../assets/locales/th/translation.json';

export default (lang) => {
  i18n.use(initReactI18next).init({
    lng: lang,
    resources: {
      en: {
        translation: enTrans,
      },
      my: {
        translation: enMyTrans,
      },
      vi: {
        translation: viTrans,
      },
      th: {
        translation: thTrans,
      },
      'zh-hant': {
        translation: zhHantTrans,
      },
      'zh-cn': {
        translation: zhCnTrans,
      },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    lowerCaseLng: true,
  });
};

export const supportedLangs = ['en', 'my', 'zh-hant', 'zh-cn'];
