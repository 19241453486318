import { spawn } from 'redux-saga/effects';
import appSaga from './app'
import primaryAuthSaga from '../pages/PrimaryAuth/sagas';
import seniorAuthSaga from '../pages/SeniorAuth/sagas';
import PrimaryRecordSaga from '../pages/Record/components/PrimaryRecord/sagas';
import SeniorRecordSaga from '../pages/Record/components/SeniorRecord/sagas';
import basicSaga from './basic/index';

export default function* rootSaga() {
  yield spawn(appSaga);
  yield spawn(primaryAuthSaga);
  yield spawn(seniorAuthSaga);
  yield spawn(basicSaga);
  yield spawn(PrimaryRecordSaga);
  yield spawn(SeniorRecordSaga);
}
