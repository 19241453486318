import { createAction } from 'redux-actions';

// export const GET_PRIMARY_FORM_DATAS = 'GET_PRIMARY_FORM_DATAS';
// export const SET_PRIMARY_FORM_DATAS = 'SET_PRIMARY_FORM_DATAS';

// export const GET_PRIMARY_TIME_LINE = 'GET_PRIMARY_TIME_LINE';
// export const SET_PRIMARY_TIME_LINE = 'SET_PRIMARY_TIME_LINE';

export const getPrimaryFormDatas = createAction('GET_PRIMARY_FORM_DATAS', (slPaymentMerchantId) => ({ slPaymentMerchantId }));
export const setPrimaryFormDatas = createAction('SET_PRIMARY_FORM_DATAS');

export const setPrimaryTimeLine = createAction('SET_PRIMARY_TIME_LINE');
