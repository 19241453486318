import * as types from './actionTypes';
import { handleActions } from 'redux-actions';
import { SeniorAuthState } from '../../types/store';

const initState: SeniorAuthState = {
  primaryRecord: {},
  submitLoading: false,
  submitError: null,
  submitSubError: null,
};

export default handleActions(
  new Map([
    [
      types.setPrimaryRecord,
      (state: SeniorAuthState, action: any) => {
        return { ...state, primaryRecord: { ...action.payload } };
      },
    ],
    [
      types.setSubmitLoading,
      (state: SeniorAuthState, action: any) => {
        return { ...state, submitLoading: action.payload };
      },
    ],
    [
      types.setSubmitError,
      (state: SeniorAuthState, action: any) => {
        return { ...state, submitError: action.payload };
      }
    ],
    [
      types.setSubmitSubError,
      (state: SeniorAuthState, action: any) => {
        return { ...state, submitSubError: action.payload };
      }
    ]
  ]) as { [key: string]: any },
  initState,
);
