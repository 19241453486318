import { zonedTimeToUtc } from 'date-fns-tz'
import { call, put, getContext, takeLatest } from 'redux-saga/effects';
import * as types from '../actionTypes';
import { BusinessType, FormKeyMap } from '@/components/PrimaryAuthFormArea/constant';
import { TimeLine } from '@/types/store'
import _ from 'lodash';

const PRIMARY_FORM_DATAS_URL = 'kyc/info';

interface RecordData {
  business_type?: BusinessType[keyof BusinessType];
  [key: string]: any;
}

const genFormatRecord = (data): RecordData => {
  if (!data || !data.merchantType) return {};
  const businessType = data.merchantType + '';
  const { storeInfo, legalPerson, contactInfo, directorList } = data;
  console.log("getPrimaryData",data)
  let result: any = {
    business_type: businessType,
    [FormKeyMap.email]: contactInfo?.email,
    [FormKeyMap.phone]: contactInfo?.phone,
    [FormKeyMap.firstName]: legalPerson?.firstName,
    [FormKeyMap.lastName]: legalPerson?.lastName,
    [FormKeyMap.zhFirstName]: legalPerson?.zhFirstName,
    [FormKeyMap.zhLastName]: legalPerson?.zhLastName,
    [FormKeyMap.gender]: legalPerson?.gender,
    [FormKeyMap.idType]: legalPerson?.idType,
    [FormKeyMap.idNumber]: legalPerson?.idNumber,
    [FormKeyMap.nationality]: legalPerson?.nationality,
    [FormKeyMap.shopNationality]: storeInfo?.country,
  };
  if (businessType !== BusinessType.individual) {
    result = {
      ...result,
      [FormKeyMap.companyId]: data.businessLicenseInfo?.businessLicenseNumber,
      [FormKeyMap.companyName]: data.businessLicenseInfo?.merchantName,
      [FormKeyMap.companyZhName]: data.zhName,
      [FormKeyMap.companyNationality]: storeInfo?.country,
      [FormKeyMap.establishmentDate]: data.businessLicenseInfo?.dateOfEstablishment,
      [FormKeyMap.totalCapital]: data.businessLicenseInfo?.registeredCapital,
    };
  }
  if (businessType === BusinessType.limitedCompany && !_.isEmpty(directorList)) {
    result[FormKeyMap.firstName] = directorList[0].firstName;
    result[FormKeyMap.lastName] = directorList[0].lastName;
    result[FormKeyMap.zhFirstName] = directorList[0].zhFirstName;
    result[FormKeyMap.zhLastName] = directorList[0].zhLastName;
    result[FormKeyMap.idNumber] = directorList[0].idNumber;
    result[FormKeyMap.idType] = directorList[0].idType;
    result[FormKeyMap.nationality] = directorList[0].nationality;
    result[FormKeyMap.gender] = directorList[0].gender;
  }
  console.log("getPrimaryFormatRecord",result)
  return result;
};

function* getPrimaryFormDatas({ payload: { slPaymentMerchantId, resolve } }: ReturnType<typeof types.getPrimaryFormDatas>) {
  const api = yield getContext('api');
  try {
    // const query = slPaymentMerchantId ? `?sl_payment_merchant_id=${slPaymentMerchantId}` : '';
    const result = yield call(api.get, PRIMARY_FORM_DATAS_URL);
      console.log("PrimaryResult",result)
    const resultData = genFormatRecord(result.data);
    yield put({ type: types.setPrimaryFormDatas, payload: resultData });
    if (!slPaymentMerchantId) {
      yield put({ type: types.setPrimaryTimeLine, payload: genTimeline(result.data) });
    }
    if (resolve) {
      resolve(resultData);
    }
  } catch {
    if (resolve) {
      resolve({});
    }
  }
}

const genTimeline = (data): TimeLine[] => {
  return [
    {
      eventType: 'basic_create',
      by: data?.contactInfo.email,
      create: zonedTimeToUtc(data.createTime, 'GMT').valueOf(),
    },
  ];
};

export default function* watchPrimaryAuthRecord() {
  yield takeLatest(types.getPrimaryFormDatas, getPrimaryFormDatas);
}
