import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Theme } from '@shopline/dashboard-ui';
import LoadingScreen from './components/LoadingScreen';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PageUrls } from './utils/constant';
import { setLang } from './sagas/app/actionTypes'
import i18next from 'i18next';
import { getAdminV2 } from '@/utils/common';

const PrimaryAuth = lazy(() => import('./pages/PrimaryAuth'));
const SeniorAuth = lazy(() => import('./pages/SeniorAuth'));
const Record = lazy(() => import('./pages/Record'));

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px 0 90px;
  box-sizing: border-box;
  min-height: 100vh;
`;
const GlobalStyle = createGlobalStyle`
  html {
    background-color: #edeef0;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  };
  body {
    margin: 0;
  }
  main {
    display: block;
  }
  a {
    background-color: transparent;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
`;

function App({ store, history }) {
  useEffect(() => {
    store.dispatch(setLang(i18next.language));
  }, [store]);
  useEffect(() => {
    const handleListenMessage = (event) => {
      const { lang } = event.data || {};
      store.dispatch(setLang(lang));
    };
    if (window) {
      window.addEventListener('message', handleListenMessage, false);
    }
    return () => {
      window.removeEventListener('message', handleListenMessage);
    };
  }, [store]);

  const handle = getAdminV2() ? '/admin/kyc' : '/:handle';
  // when develop, please use below handle when u want 2.0
  // const handle = '/admin/v2';

  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <ConnectedRouter history={history}>
          <AppContainer>
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                <Route exact path={handle + PageUrls.primary} component={PrimaryAuth} />
                <Route exact path={handle + PageUrls.senior} component={SeniorAuth} />
                <Route exact path={handle + PageUrls.record} component={Record} />
                {/* <Redirect path="/" to={PageUrls.primary}></Redirect> */}
                {/* <Redirect from="*" to={} /> */}
              </Switch>
            </Suspense>
          </AppContainer>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
