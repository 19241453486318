import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appReducers from '../sagas/app/reducers';
import primaryReducers from '../pages/PrimaryAuth/reducers';
import seniorReducers from '../pages/SeniorAuth/reducers';
import basicReducers from '../sagas/basic/reducers';
import primaryRecordReducers from '../pages/Record/components/PrimaryRecord/reducers';
import seniorRecordReducers from '../pages/Record/components/SeniorRecord/reducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducers,
    primaryAuth: primaryReducers,
    seniorAuth: seniorReducers,
    basic: basicReducers,
    primaryRecord: primaryRecordReducers,
    seniorRecord: seniorRecordReducers,
  });
