import _ from 'lodash';

const emailPattern = /[^a-zA-Z0-9._@-]/;
const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const numberLetterPattern = /[^a-zA-Z0-9]/;
const noNumberPattern = /[^0-9]/;
const IMG_MAX_SIZE = 1024 * 1024 * 10;
const imgTypePattern = /\/jpg$|\/jpeg$|\/png$|\/pdf$/;
const dateFormatPattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
const hyphenBankAccountPattern = /^\d{3}-\d+$/;
const sgBankAccountPattern = /^\d{3}-\d{1,9}$/;
const englishNamePattern = /^[\w]+[\s]?[\w]+/;
const onlyChinesePattern = /^[\u4E00-\u9FA5]+$/;
const onlyLetterPattern = /^[A-Za-z]+$/;
const sgCompanyNumbPattern = /^[a-zA-Z0-9]{9,10}$/;
const anyChinesePattern = /[\u4E00-\u9FA5]/;

const validWrap: (
  errCondition: boolean,
  msg: string,
  t: Function,
  tParams?: { [key: string]: string },
) => [boolean, Function[]] = (errCondition, msg, t, tParams) => {
  let hasError = false;
  let renderErrMsg: Function[] = [];
  if (errCondition) {
    hasError = true;
    renderErrMsg.push(() => t(msg, tParams));
  }

  return [hasError, renderErrMsg];
};

export const validNUmber = (msg) => (data, t) => {
  return validWrap(noNumberPattern.test(data), msg, t);
};

// export const validSGPostalcode = (msg) => (data, t) => {
//   return validWrap(noNumberPattern.test(data), msg, t);
// };

export const validNumberAndLetter = (msg) => (data, t) => {
  return validWrap(_.isEmpty(data) || numberLetterPattern.test(data), msg, t);
};

export const validRegexPattern = (msg, regex) => (data, t) => {
  return validWrap(_.isEmpty(data) || !regex.test(data), msg, t);
}

export const validRequired = (msg) => (data, t) => {
  return validWrap(_.isEmpty(data), msg, t);
};

export const validEmail = (msgs) => (data, t) => {
  if (!data) return [false, []];
  let [hasError, renderErrMsg]: readonly [boolean, Function[]] = validWrap(emailPattern.test(data), msgs[0], t);
  if (!data.includes('@')) {
    hasError = true;
    renderErrMsg.push(() => t(msgs[1]));
  }
  return [hasError, renderErrMsg];
};

export const validPhone = (msg) => (data, t) => {
  return validWrap(!phonePattern.test(data), msg, t);
};

export const validImg = (msgs) => (data, t) => {
  if (!data) return [false, []];
  if (data.fileId) {
    return [false, []];
  }
  const { file } = data;
  // console.log("file",file)
  const size = file?.size || 1000; //退回状态下的修复
  const type = file.type;
  const fileNameLength = file.name.substring(0,file.name.lastIndexOf(".")).length

  let [hasError, renderErrMsg] = validWrap(!imgTypePattern.test(type), msgs[0], t);
  // let [hasError2, renderErrMsg2] = validWrap(size > IMG_MAX_SIZE, msgs[1], t);
  let [hasError2, renderErrMsg2] = validWrap(false, msgs[1], t);
  let [hasError3, renderErrMsg3] = validWrap(fileNameLength>128, msgs[2], t);

  return [hasError || hasError2 || hasError3, renderErrMsg.concat(renderErrMsg2).concat(renderErrMsg3)];
};

export const validImgs = (msgs) => (data, t) => {
  let renderErrMsgs: Function[] = [];
  _.find(data, (file) => {
    if (file.fileId) {
      return false; // skip file if file is already uploaded
    }
    const [imgTypeError, renderImgTypeErrMsg] = validWrap(!imgTypePattern.test(file.type), msgs[0], t);
    // const [imgSizeError, renderImgSizeErrMsg] = validWrap(file.size > IMG_MAX_SIZE, msgs[1], t);
    const [imgSizeError, renderImgSizeErrMsg] = validWrap(false, msgs[1], t);
    if (imgTypeError) {
      renderErrMsgs = renderErrMsgs.concat(renderImgTypeErrMsg);
    }
    if (imgSizeError) {
      renderErrMsgs = renderErrMsgs.concat(renderImgSizeErrMsg);
    }
    return imgTypeError || imgSizeError;
  });
  return [!_.isEmpty(renderErrMsgs), renderErrMsgs];
};

export const validDate = (msg) => (data, t) => {
  if (!data) {
    return [false, []];
  }
  // console.log(new Date().getTime() - new Date(data).getTime())
  // console.log((new Date().getTime() - new Date(data).getTime())/(24*3600*1000))
  return validWrap(
    !dateFormatPattern.test(data)
    || new Date(data) > new Date()
    || ((new Date().getTime() - new Date(data).getTime())/(24*3600*1000)<18*365), msg, t);
};

export const validDate2 = (msg) => (data, t) => {
  if (!data) {
    return [false, []];
  }
  return validWrap(!dateFormatPattern.test(data) || new Date(data) > new Date(), msg, t);
};

export const validDate3 = (msg) => (data, t) => {
  if (!data) {
    return [false, []];
  }
  return validWrap(!dateFormatPattern.test(data), msg, t);
};

export const validLength = (msg, length) => (data, t) => {
  if (!data) return [false, []];
  return validWrap(data.length > length, msg, t, { length });
};

export const validOnlyLength = (msg, length) => (data, t) => {
  if (!data) return [false, []];
  return validWrap(data.length != length, msg, t, { length });
};


export const validHkId = (msg) => (data, t) => {
  let invalid = true;
  if (!_.isEmpty(data)) {
    let sum = 0;
    const matches = data.toUpperCase().match(/^([A-Z]{1,2})([0-9]{6})\(([0-9A])\)$/);
    if (matches && matches.length === 4) {
      if (matches[1].length === 1) {
        sum += 36 * 9 + (matches[1].charCodeAt(0) - 55) * 8;
      } else {
        sum += (matches[1].charCodeAt(0) - 55) * 9 + (matches[1].charCodeAt(1) - 55) * 8;
      }
      for (let i = 0; i < matches[2].length; i++) {
        sum += Number(matches[2].charAt(i)) * (7 - i);
      }
      let checkDigit;
      let remainder = sum % 11;
      if (remainder === 0) {
        checkDigit = '0';
      } else if (remainder === 1) {
        checkDigit = 'A';
      } else {
        checkDigit = (11 - remainder).toString();
      }
      invalid = checkDigit !== matches[3];
    }
  }
  return validWrap(invalid, msg, t);
};

export const validHyphenBankAccount = (msg) => (data, t) => {
  return validWrap(!hyphenBankAccountPattern.test(data), msg, t);
};

export const validSGBankAccount = (msg) => (data, t) => {
  return validWrap(!sgBankAccountPattern.test(data), msg, t);
};

export const validateEnglishName = (msg) => (data, t) => {
  return validWrap(!englishNamePattern.test(data), msg, t);
};

export const validateChinese = (msg) => (data, t) => {
  return validWrap(!onlyChinesePattern.test(data), msg, t);
};

export const validBankSwiftCode = (msg) => (data, t) => {
  return validWrap(_.isEmpty(data) || data.length !== 11, msg, t);
};

export const validSGBankSwiftCode = (msg) => (data, t) => {
  let invalid = true;
  if (!_.isEmpty(data) && [8, 11].includes(data.length)) {
    invalid = data.substring(4, 6) !== 'SG' && !onlyLetterPattern.test(data.substring(0, 4));
  }
  return validWrap(invalid, msg, t);
};

export const validSGCompanyName = (msg) => (data, t) => {
  return validWrap(anyChinesePattern.test(data), msg, t);
};

export const validSGCompanyNum = (msg) => (data, t) => {
  return validWrap(!sgCompanyNumbPattern.test(data), msg, t);
};
