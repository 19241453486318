import { push } from 'connected-react-router';
import { takeLatest, getContext, call, put, select } from 'redux-saga/effects';
import * as types from '../actionTypes';
import { BusinessType } from '@/test/constant';
import { BankAccountType, FormKeyMap, IdTypeValueMap } from '@/components/SeniorAuthFormArea/constant'
import _get from 'lodash/get'
import _map from 'lodash/map';
import { getAdminV2, getHandle } from '@/utils/common';
import { PageUrls, RecordType } from '@/utils/constant'
import _ from 'lodash';
import { CountryValueMap,GenderValueMap } from '@/components/PrimaryAuthFormArea/constant';
import TW_City_District_Map from '@/assets/locales/tw_city_district_map';

const ADMIN_V2 = getAdminV2();
const SENIOR_AUTH_URL = 'kyc/advance';

function* setLoading(status: boolean) {
  yield put({ type: types.setSubmitLoading, payload: status });
}

function *setSubmitError(error: string | null) {
  yield put({ type: types.setSubmitError, payload: error })
}

function *setSubmitSubError(error: string | null) {
  yield put({ type: types.setSubmitSubError, payload: error })
}

function getPostDataForAdvance(formDatas, businessType, storeCurrency) {
  console.log("getPostDataForAdvance-formDatas",formDatas)
  //TW 邮编传参 以及县市去掉邮编
  if((formDatas[FormKeyMap.companyNationality] || formDatas[FormKeyMap.nationality])===CountryValueMap.tw){
  if(formDatas[FormKeyMap.liveCountryRegion]===CountryValueMap.tw){
    formDatas[FormKeyMap.livePostcode] =  formDatas[FormKeyMap.liveDistrict].substring(0,3)
    formDatas[FormKeyMap.liveDistrict] = formDatas[FormKeyMap.liveDistrict].substr(4)
  }
  if(formDatas[FormKeyMap.businessCountryRegion]===CountryValueMap.tw){
    if(window.sessionStorage.getItem(FormKeyMap.businessCity)){
      formDatas[FormKeyMap.businessCity] = window.sessionStorage.getItem(FormKeyMap.businessCity)
    }
    if(window.sessionStorage.getItem(FormKeyMap.businessDistrict)){
      formDatas[FormKeyMap.businessDistrict] = window.sessionStorage.getItem(FormKeyMap.businessDistrict)
    }
    if(window.sessionStorage.getItem(FormKeyMap.businessAddress)){
      formDatas[FormKeyMap.businessAddress] = window.sessionStorage.getItem(FormKeyMap.businessAddress)
    }
    formDatas[FormKeyMap.businessPostcode] =  formDatas[FormKeyMap.businessDistrict].substring(0,3)
    formDatas[FormKeyMap.businessDistrict] = formDatas[FormKeyMap.businessDistrict].substr(4)
  }
  if(formDatas[FormKeyMap.shopRegion]===CountryValueMap.tw){
    formDatas[FormKeyMap.shopPostcode] = formDatas[FormKeyMap.shopDistrict].substring(0,3)
    formDatas[FormKeyMap.shopDistrict] = formDatas[FormKeyMap.shopDistrict].substr(4)
  }

}
  
 
  const data: any = {
    legalPerson: {
      idExpiration: formDatas[FormKeyMap.idExpiryDate] || '',
      dateOfBirth: formDatas[FormKeyMap.dateOfBirth] || '',
      country: formDatas[FormKeyMap.liveCountryRegion],
      state: formDatas[FormKeyMap.liveState] || '',
      city: formDatas[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.liveCity]]||formDatas[FormKeyMap.liveCity]):formDatas[FormKeyMap.liveCity],//TW 取中文  TW_City_District_Map
      enCity:formDatas[FormKeyMap.liveCity],
      region: formDatas[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.liveDistrict]] ||formDatas[FormKeyMap.liveDistrict]):formDatas[FormKeyMap.liveDistrict],// TW 取中文
      enRegion:formDatas[FormKeyMap.liveDistrict] || '',//TODO TW 取英文
      postalCode: formDatas[FormKeyMap.livePostcode] || '',
      address: formDatas[FormKeyMap.liveAddress],
      street: formDatas[FormKeyMap.liveStreet],
      houseNumberOrName: formDatas[FormKeyMap.liveHouseNum],
      idType: formDatas[FormKeyMap.idType],
      frontIdPic: formDatas[FormKeyMap.idType] !== IdTypeValueMap.PASSPORT ? formDatas[FormKeyMap.uploadIdImgFront]?.fileId : formDatas[FormKeyMap.passportImg]?.fileId,
      backIdPic: formDatas[FormKeyMap.idType] !== IdTypeValueMap.PASSPORT ? formDatas[FormKeyMap.uploadIdImgBack]?.fileId : undefined,
      //TODO  TW 特殊处理
      zhFirstName: formDatas[FormKeyMap.zhFirstName] || '',
      zhLastName: formDatas[FormKeyMap.zhLastName] || '',
      firstName: formDatas[FormKeyMap.firstName] || '',
      lastName: formDatas[FormKeyMap.lastName] || '',
      //
    },
    bankList: formDatas.banks?.map((b) => {
      const bank = {
        country: b[FormKeyMap.accountRegion],
        bankName: b[FormKeyMap.accountBankName],
        bankCode: b[FormKeyMap.accountBankCode],
        branchName: b[FormKeyMap.bankBranchName] || '',
        // branchCode: b[FormKeyMap.bankBranchCode] || (b[FormKeyMap.bankAccountType] === BankAccountType.PRIVATE && b[FormKeyMap.accountRegion]!=="CN"?'000':''),
        branchCode: b[FormKeyMap.bankBranchCode],
        accountName: b[FormKeyMap.bankAccountName] || formDatas[FormKeyMap.accountPersonName] || '',
        accountNo: b[FormKeyMap.bankAccount],
        currency: b[FormKeyMap.bankAccountCurrency],
        accountType: b[FormKeyMap.bankAccountType],
        isDefault: b[FormKeyMap.isDefaultBankAccount] ? '1' : '0',
        swiftCode: b[FormKeyMap.bankSwiftCode] || '',
        checkBillPic: b[FormKeyMap.certBankImg]?.fileId,
      };
      return bank;
    }),
    services: formDatas[FormKeyMap.introGoodsType],
    gmv: formDatas[FormKeyMap.expectedMonthlySale],
  }

  if((formDatas[FormKeyMap.companyNationality] || formDatas[FormKeyMap.nationality])===CountryValueMap.tw){
    data.bussinessAddressInfo = {
    country: formDatas[FormKeyMap.businessCountryRegion] || "",
    state: formDatas[FormKeyMap.businessState] || "",
    city: formDatas[FormKeyMap.businessCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.businessCity]]||formDatas[FormKeyMap.businessCity]):formDatas[FormKeyMap.businessCity],//TW 取中文  TW_City_District_Map
    enCity:formDatas[FormKeyMap.businessCity] || "",
    region: formDatas[FormKeyMap.businessCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.businessDistrict]] ||formDatas[FormKeyMap.businessDistrict]):formDatas[FormKeyMap.businessDistrict],// TW 取中文
    enRegion: formDatas[FormKeyMap.businessDistrict] || "",
    postalCode: formDatas[FormKeyMap.businessPostcode] || "",
    address:formDatas[FormKeyMap.businessAddress] || "",
    // enAddress:formDatas[FormKeyMap.liveState] || "",
    street: formDatas[FormKeyMap.businessStreet] || "",
    houseNumberOrName: formDatas[FormKeyMap.businessHouseNum] || "",
    }
  }

  if (formDatas[FormKeyMap.nationality] !== CountryValueMap.cn) {
    const bank = {
      country: formDatas[FormKeyMap.accountRegion],
      bankName: formDatas[FormKeyMap.accountBankName],
      bankCode: formDatas[FormKeyMap.accountBankCode],
      branchName: '',
      branchCode: formDatas[FormKeyMap.bankBranchCode] || '-',
      accountName: formDatas[FormKeyMap.accountPersonName],
      accountNo: formDatas[FormKeyMap.bankAccount],
      currency: storeCurrency,
      accountType: businessType === BusinessType.individual ? BankAccountType.PRIVATE : BankAccountType.PUBLIC,
      isDefault: '1',
      swiftCode: formDatas[FormKeyMap.bankSwiftCode],
      checkBillPic:formDatas[FormKeyMap.certBankImg]?.fileId,
    };
    // let bankCheckBillPic  =''
    // if((businessType === BusinessType.individual || businessType === BusinessType.limitedCompany) && formDatas[FormKeyMap.nationality] !== CountryValueMap.tw && formDatas[FormKeyMap.nationality] !== CountryValueMap.sg){
    //   bankCheckBillPic = formDatas[FormKeyMap.upload6MonthBill]?.fileId
    // }
    // else if((businessType === BusinessType.sole || businessType === BusinessType.partnership) && formDatas[FormKeyMap.nationality] !== CountryValueMap.tw){
    //   bankCheckBillPic = formDatas[FormKeyMap.upload6MonthBill]?.fileId
    // }
    // else{
    //   bankCheckBillPic = formDatas[FormKeyMap.certBankImg]?.fileId
    // }
    // bank.checkBillPic = businessType === BusinessType.individual
    // ? (formDatas[FormKeyMap.upload6MonthBill]?.fileId || formDatas[FormKeyMap.certBankImg]?.fileId)
    // : (formDatas[FormKeyMap.certBankImg]?.fileId || formDatas[FormKeyMap.upload6MonthBill]?.fileId)
    data.bankList = [bank];
  }

  if ([BusinessType.partnership, BusinessType.limitedCompany, BusinessType.sole, BusinessType.enterprise].includes(businessType)) {
    data.addressInfo = {
      country: formDatas[FormKeyMap.shopRegion],
      state: formDatas[FormKeyMap.shopState] || '', 
      city: formDatas[FormKeyMap.shopRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.shopCity]]||formDatas[FormKeyMap.shopCity]):formDatas[FormKeyMap.shopCity],//TW 取中文  TW_City_District_Map
      enCity: formDatas[FormKeyMap.shopCity],//TODO TW 取英文
      region:formDatas[FormKeyMap.shopRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.shopDistrict]]||formDatas[FormKeyMap.shopDistrict]):formDatas[FormKeyMap.shopDistrict],//TW 取中文  TW_City_District_Map
      enRegion:formDatas[FormKeyMap.shopDistrict] || '',//TODO TW 取英文
      postalCode:formDatas[FormKeyMap.shopPostcode] || '',
      address: formDatas[FormKeyMap.shopAddress],
      street: formDatas[FormKeyMap.shopStreet],
      houseNumberOrName: formDatas[FormKeyMap.shopHouseNum],
    }
    data.businessLicenseInfo = {
      certificateType: formDatas[FormKeyMap.proofType],
      businessTime: formDatas[FormKeyMap.certDate],
      businessLicensePicList: JSON.stringify(_map(formDatas[FormKeyMap.certBusinessOrCompanyImg], 'fileId')),
    }
  }

  if ([BusinessType.individualIndustrialAndCommerceHousehold].includes(businessType)) {
    data.businessLicenseInfo = {
      certificateType: formDatas[FormKeyMap.proofType],
      businessTime: formDatas[FormKeyMap.certDate],
      businessLicensePicList: JSON.stringify(_map(formDatas[FormKeyMap.certBusinessOrCompanyImg], 'fileId')),
    }
  }

  if ([BusinessType.limitedCompany,BusinessType.enterprise].includes(businessType)) {
    data.directorList = formDatas.directors.map((d) => {
      const director: any = {
        firstName: d[FormKeyMap.firstName] || '',
        lastName: d[FormKeyMap.lastName] || '',
        zhFirstName: d[FormKeyMap.zhFirstName] || '',
        zhLastName: d[FormKeyMap.zhLastName] || '',
        nationality: d[FormKeyMap.nationality] || '',
        idType: d[FormKeyMap.idType] || '',
        idNumber: d[FormKeyMap.idNumber] || '',
        gender: d[FormKeyMap.gender] || GenderValueMap.male,
        idExpiration: d[FormKeyMap.idExpiryDate],
        dateOfBirth: d[FormKeyMap.dateOfBirth] ,
        country: d[FormKeyMap.liveCountryRegion],
        state: d[FormKeyMap.liveState] || '',
        city: d[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[d[FormKeyMap.liveCity]]||d[FormKeyMap.liveCity]):d[FormKeyMap.liveCity],//TW 取中文  TW_City_District_Map
        enCity: d[FormKeyMap.liveCity],
        region: d[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[d[FormKeyMap.liveDistrict].substr(4)] ||d[FormKeyMap.liveDistrict].substr(4)):d[FormKeyMap.liveDistrict],// TW 取中文
        enRegion: d[FormKeyMap.liveDistrict],
        postalCode: d[FormKeyMap.livePostcode] || (d[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?d[FormKeyMap.liveDistrict].substring(0,3):''),
        address: d[FormKeyMap.liveAddress],
        street: d[FormKeyMap.liveStreet],
        houseNumberOrName: d[FormKeyMap.liveHouseNum],
      };
      if (d[FormKeyMap.idType] === IdTypeValueMap.PASSPORT) {
        director.frontIdPic = d[FormKeyMap.passportImg]?.fileId;
        director.backIdPic = null;
      } else {
        director.frontIdPic = d[FormKeyMap.uploadIdImgFront]?.fileId;
        director.backIdPic = d[FormKeyMap.uploadIdImgBack]?.fileId;
      }
      return director;
    });
    
    const defaultDirector = formDatas[FormKeyMap.defaultDirector];
    if (!_.isEmpty(defaultDirector)) {
      defaultDirector.dateOfBirth = formDatas[FormKeyMap.dateOfBirth];
      defaultDirector.idType = formDatas[FormKeyMap.idType];
      defaultDirector.city = (formDatas[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.liveCity]] || formDatas[FormKeyMap.liveCity]) : formDatas[FormKeyMap.liveCity] );//TW 取中文  TW_City_District_Map
      defaultDirector.enCity = formDatas[FormKeyMap.liveCity];
      defaultDirector.street = formDatas[FormKeyMap.liveStreet];
      defaultDirector.houseNumberOrName = formDatas[FormKeyMap.liveHouseNum];
      defaultDirector.postalCode = formDatas[FormKeyMap.livePostcode];
      defaultDirector.address = formDatas[FormKeyMap.liveAddress];
      defaultDirector.region = (formDatas[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[formDatas[FormKeyMap.liveDistrict]] || formDatas[FormKeyMap.liveDistrict]): formDatas[FormKeyMap.liveDistrict]);//TW 取中文  TW_City_District_Map
      defaultDirector.enRegion = formDatas[FormKeyMap.liveDistrict];
      if (formDatas[FormKeyMap.idType] === IdTypeValueMap.PASSPORT) {
        defaultDirector.frontIdPic = formDatas[FormKeyMap.passportImg]?.fileId;
        defaultDirector.backIdPic = null;
      } else {
        defaultDirector.frontIdPic = formDatas[FormKeyMap.uploadIdImgFront]?.fileId;
        defaultDirector.backIdPic = formDatas[FormKeyMap.uploadIdImgBack]?.fileId;
      }
      if(formDatas[FormKeyMap.liveCountryRegion]!==CountryValueMap.tw){
        data.directorList = [defaultDirector].concat(data.directorList);
      }
      else{
        defaultDirector.country = formDatas[FormKeyMap.liveCountryRegion]
        defaultDirector.firstName = formDatas[FormKeyMap.firstName]|| ''
        defaultDirector.idExpiration= formDatas[FormKeyMap.idExpiryDate]
        defaultDirector.idType=formDatas[FormKeyMap.idType]
        defaultDirector.lastName= formDatas[FormKeyMap.lastName] || ''
        defaultDirector.state=formDatas[FormKeyMap.liveState] || ''
        defaultDirector.zhFirstName = formDatas[FormKeyMap.zhFirstName]|| ''
        defaultDirector.zhLastName = formDatas[FormKeyMap.zhLastName]|| ''
        data.directorList = [defaultDirector]
      }
    }
  }
    
  if ([BusinessType.partnership, BusinessType.limitedCompany, BusinessType.enterprise].includes(businessType)) {
    data.shareholderList = (formDatas.shareholders || []).map((sh) => {
      const item: any = {
        firstName: sh[FormKeyMap.firstName] || '',
        lastName: sh[FormKeyMap.lastName] || '',
        zhFirstName: sh[FormKeyMap.zhFirstName] || '',
        zhLastName: sh[FormKeyMap.zhLastName] || '',
        nationality: sh[FormKeyMap.nationality] || '',
        idType: sh[FormKeyMap.idType] || '',
        idNumber: sh[FormKeyMap.idNumber] || '',
        gender: sh[FormKeyMap.gender] || GenderValueMap.male,
        idExpiration: sh[FormKeyMap.idExpiryDate],
        dateOfBirth: sh[FormKeyMap.dateOfBirth] || (sh[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?formDatas[FormKeyMap.dateOfBirth]:''),//TODO TW 特殊处理
        country: sh[FormKeyMap.liveCountryRegion] || (sh[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?CountryValueMap.tw:''),//TODO  TW 特殊处理
        state: sh[FormKeyMap.liveState] || '',   
        city: sh[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[sh[FormKeyMap.liveCity]]||sh[FormKeyMap.liveCity]):sh[FormKeyMap.liveCity],//TW 取中文  TW_City_District_Map
        enCity: sh[FormKeyMap.liveCity],
        region:sh[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?(TW_City_District_Map[sh[FormKeyMap.liveDistrict].substr(4)]||sh[FormKeyMap.liveDistrict].substr(4)):sh[FormKeyMap.liveDistrict],//TW 取中文  TW_City_District_Map
        enRegion: sh[FormKeyMap.liveDistrict],
        postalCode: sh[FormKeyMap.livePostcode] || (sh[FormKeyMap.liveCountryRegion]===CountryValueMap.tw?sh[FormKeyMap.liveDistrict].substring(0,3):''),
        address: sh[FormKeyMap.liveAddress],
        street: sh[FormKeyMap.liveStreet],
        houseNumberOrName: sh[FormKeyMap.liveHouseNum],
      }

      if (sh[FormKeyMap.idType] === IdTypeValueMap.PASSPORT) {
        item.frontIdPic = sh[FormKeyMap.passportImg]?.fileId;
        item.backIdPic = null;
      } else {
        item.frontIdPic = sh[FormKeyMap.uploadIdImgFront]?.fileId ||  (formDatas[FormKeyMap.accountRegion]===CountryValueMap.tw?formDatas[FormKeyMap.uploadIdImgFront]?.fileId:'');
        item.backIdPic = sh[FormKeyMap.uploadIdImgBack]?.fileId ||  (formDatas[FormKeyMap.accountRegion]===CountryValueMap.tw?formDatas[FormKeyMap.uploadIdImgBack]?.fileId:'');
      }
      //TODO  TW 特殊处理
      // if(formDatas[FormKeyMap.nationality]===CountryValueMap.tw){
      //   if(!item.frontIdPic){
      //     item.frontIdPic=formDatas[FormKeyMap.idType] !== IdTypeValueMap.PASSPORT ? formDatas[FormKeyMap.uploadIdImgFront]?.fileId : formDatas[FormKeyMap.passportImg]?.fileId
      //   }
      //   if(!item.backIdPic){
      //     item.backIdPic=formDatas[FormKeyMap.idType] !== IdTypeValueMap.PASSPORT ? formDatas[FormKeyMap.uploadIdImgBack]?.fileId : undefined
      //   }
      // }
      return item;
    });
  }
  console.log("postSeniorData",data)
  return data;
}

function* submitSeniorAuth({ payload: { formDatas, businessType, storeCurrency } }: ReturnType<typeof types.submitSeniorAuth>) {
  const { router } = yield select((store) => store);
  console.log("submitSeniorFormDatas",formDatas)
  console.log("submitSeniorBusinessType",businessType)
  console.log("submitSeniorStoreCurrency",storeCurrency)
  yield setLoading(true);
  yield setSubmitError(null);
  try {
    const api = yield getContext('api');

    const result = yield call(api.post, SENIOR_AUTH_URL, getPostDataForAdvance(formDatas, businessType, storeCurrency));
    // console.log('call admin 2', result);

    if (window.parent !== window.self) {
      window.parent.postMessage({ type: 'redirectToPaymentCenter' }, '*')
    } else {
      // redirect to preview page if not in iframe as a fallback
      const handle = getHandle(router.location.pathname, PageUrls.senior, ADMIN_V2);
      yield put(push('/' + handle + PageUrls.record + '?type=' + RecordType.senior));
    }
  } catch (e) {
    const errorMsg = _get(e, 'response.data.msg')
    const subErrorMsg = _get(e,'response.data.data.subMsg')
    if (errorMsg) {
      yield setSubmitSubError(subErrorMsg)
      yield setSubmitError(errorMsg);
    }
    console.log('[Advance submission failed]', e);
  } finally {
    yield setLoading(false);
  }
}

export default function* watchSubmitSeniorAuth() {
  yield takeLatest(types.submitSeniorAuth, submitSeniorAuth);
}
