import { createAction } from 'redux-actions';

export const submitSeniorAuth = createAction('SUBMIT_SENIOR_AUTH', (formDatas, businessType, storeCurrency) => ({
  formDatas,
  businessType,
  storeCurrency,
}));

export const getPrimaryRecord = createAction('SENOR/GET_PRIMARY_RECORD');

export const setPrimaryRecord = createAction('SENIOR/SET_PRIMARY_RECORD');

export const setSubmitLoading = createAction('senior/setSubmitLoading');

export const setSubmitError = createAction('senior/setSubmitError');

export const setSubmitSubError = createAction('senior/submitSubError');
